import ReactGA from 'react-ga'

export const recordEvent = (action, label) => {
  ReactGA.event({
    category: `Asylum Guide`,
    action,
    label
  })
}

export const initGA = trackingID => {
  ReactGA.initialize(trackingID)
}
