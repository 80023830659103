import React from 'react'
import Slugify from '../../javascript/slugify'
import logo from './logo.png'
import './Navigator.css'
import { goToGlossary } from '../../utils'

export default props => (
  <section className="header_title">
    <section className="nav">
      <h4 className="nav_left_column">
        <Slugify classname="link-inHeader link_font" url={'/'}>
          Asylum Law Guide
        </Slugify>
      </h4>
      <h4 className="nav_right_column">
        <button
          className="link-inHeader link_font"
          onClick={() => {
            return goToGlossary(props)
          }}
        >
          Glossary of Terms
        </button>
      </h4>
    </section>
    <section className="logoSec">
      <div className="nav_logo_text">a service from</div>
      <Slugify url="https://themigrantslawproject.org" isExternal>
        <img className="nav_logo" src={logo} alt="" />
      </Slugify>
    </section>
  </section>
)
