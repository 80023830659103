import React, { Fragment } from 'react'
import { Switch } from 'react-router-dom'

import DefaultLayout from './Layout/Default'
import Sections from './Sections'
import Section1 from './Sections/Section1'
import Section2 from './Sections/Section2'
import Section3 from './Sections/Section3'
import Section4 from './Sections/Section4'
import GlossaryBox from './components/GlossaryBox'
import Acknowledgement from './components/Acknowledge'
import LegalAid from './components/LegalAidPage'
import Solicitor from './components/Referrals/Solicitor'
import SiteMap from './SiteMap'
import MPchecklist from './components/MPchecklist'
import AsylumSupport from './components/AsylumSupport'

export default () => (
  <Fragment>
    <Switch>
      <DefaultLayout exact path="/" component={Sections} />
      <DefaultLayout exact path="/sections/1/:stepId?/" component={Section1} />
      <DefaultLayout exact path="/sections/2" component={Section2} />
      <DefaultLayout exact path="/sections/3/:stepId?/" component={Section3} />
      <DefaultLayout exact path="/sections/4/:stepId?/" component={Section4} />
      <DefaultLayout exact path="/sections/:sectionId?" component={Sections} />
      <DefaultLayout exact path="/legalaid" component={LegalAid} />
      <DefaultLayout exact path="/asylum-support" component={AsylumSupport} />
      <DefaultLayout exact path="/glossary" component={GlossaryBox} />
      <DefaultLayout exact path="/acknowledgement" component={Acknowledgement} />
      <DefaultLayout exact path="/referrals/solicitor" component={Solicitor} />
      <DefaultLayout exact path="/mp-checklist" component={MPchecklist} />
      <DefaultLayout exact path="/sitemap" component={SiteMap} />
    </Switch>
  </Fragment>
)
