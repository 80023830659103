import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3first extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3second' })
      : this.setState({ url: '/sections/3/section3firstEndOfRoute' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Step 3</h1>
            <h3>Does the client have legal representation?</h3>
            <form className="form" action="#">
              <input
                onClick={this.handleLink}
                type="radio"
                name="stepThreeB"
                value="yes"
                id="stepThreeBYes"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeBYes">
                Yes
              </label>
              <br />
              <br />
              <input
                onClick={this.handleLink}
                type="radio"
                name="stepThreeB"
                value="no"
                id="stepThreeBNo"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeBNo">
                No
              </label>
              <br />
            </form>
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area" />
        </section>
      </>
    )
  }
}
export default Section3first
