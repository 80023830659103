import React from 'react'
import Slugify from '../../javascript/slugify'
export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 3</h1>

        <h3>
          Ensuring the correct first steps in claiming asylum have been
          completed and clients are aware of their rights and responsibilities
        </h3>
        <p>
          Sometimes clients may think that they have alerted the government that
          they want to claim asylum, but not in fact have done so, due to
          confusing advice on how to go about this. For example, the Home Office
          will not generally accept a written application. The best way to do
          this is to check their documents, which will enable you to find out if
          they have started the process. Ensuring the correct first steps in
          claiming asylum have been completed and clients are aware of their
          rights and responsibilities.
        </p>
        <section className="next-noRatio">
          <Slugify classname="NextButton" url={'/sections/3/section3a'}>
            Next
          </Slugify>
        </section>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
