import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>The client has not had a Screening Interview</h1>
        <p>
          If the client does not have{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice,
          </Slugify>
          they should be encouraged to do so. The client can contact the Asylum
          Screening Unit (ASU) to arrange an appointment to register their
          asylum claim{' '}
          <Slugify
            url="https://www.gov.uk/asylum-intake-unit"
            classname="link"
            isExternal
          >
            here.
          </Slugify>
          <br />
          <br />
          Check they have been complying with reporting restrictions and have
          not moved address without informing the immigration service. More
          information about reporting restrictions can be found{' '}
          <Slugify
            url="https://www.gov.uk/immigration-reporting-centres"
            classname="link"
            isExternal
          >
            here.
          </Slugify>
          <br />
          If they have moved address without informing the Immigration Service
          or have failed to comply with reporting restrictions, they should
          obtain legal advice immediately, as they are likely to be treated as
          an absconder and may be at risk of detention and removal.
          <br />
        </p>
        <p>
          It is crucially important that your client get{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice
          </Slugify>
          as soon as possible.
          <br />
          If they do not have a lawyer, we have provided a checklist for making
          a{' '}
          <Slugify url={'/referrals/solicitor'} classname="legal link">
            successful referral.
          </Slugify>
        </p>
        <p>
          Sometimes, people think that if they are moved from one address in
          asylum accommodation to another, the Home Office will be informed by
          the Asylum Support department, but this is not the case and it is
          still important to inform the Home Office. Once all these things have
          been checked, and you have established that they are just waiting for
          the Screening Interview, support the client by ensuring that they have
          legal representation and that they inform their lawyer of any concerns
          they have about delays in their case and/or raise any issues with
          their MP with information about any detrimental impact caused by the
          delays, as appropriate. We have provided a checklist for contacting an
          MP:
        </p>
        <p>
          <Slugify classname="link" url={'./MPchecklist'} isExternal>
            Checklist: Letter to MP
          </Slugify>
        </p>
        <p>
          It is very important that your client keep in contact with their
          lawyer, informing them of any change of address, and that they report
          to the Immigration Services, as required. If someone has any concerns
          about reporting or about the nature of their reporting restrictions,
          these should be discussed with their lawyer:
        </p>
        <p>
          <Slugify
            classname="link"
            url={'./ReferralToSolicitorCheckList'}
            isExternal
          >
            Checklist: Making a successful referral to a solicitor
          </Slugify>
        </p>
        <p>
          Make sure that the client understands the importance of{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            having a solicitor.
          </Slugify>
          <br />
          Make sure the client understands that they should be accessing asylum
          support if appropriate.{' '}
          <Slugify classname="link" url={'./section3second'}>
            Learn More.
          </Slugify>
          <br />
          If the client cannot access legal advice at this point, they should
          still make an appointment at the Asylum Screening Unit (ASU)
          straightaway.
        </p>
        <p>
          There is helpful information you can read through together about{' '}
          <Slugify
            url="https://righttoremain.org.uk/toolkit/screening/"
            isExternal
            classname="link"
          >
            screening interviews
          </Slugify>
          here and you should continue to try to find a solicitor. Once the
          Screening Interview has been completed, ask the client to come back
          and restart the app with them to understand the next steps in
          supporting them.
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
