import React from 'react'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>MP Check List</h1>
        <p>
          There are certain key pieces of information that you need to include
          in a letter to an MP when you are asking for help. You may need to
          contact your client’s local MP at a number of stages in the process:
          for example, delays to receiving a decision on an asylum claim after
          the substantive interview. It is important to include as much relevant
          information as possible, to help your MP help you.
        </p>
        <p>
          <ul className="listContainer noBullets">
            <li>
              <input type="checkbox" className="checkbox" id="contact" />
              <label className="agreementCheck" for="contact">
                If you are going to be the contact for this work going forward,
                your contact details.
              </label>
            </li>
            <br />
            <li>
              <input type="checkbox" className="checkbox" id="address" />
              <label className="agreementCheck" for="address">
                Individual’s name and address – to show they are a constituent
              </label>
            </li>
            <br />
            <li>
              <input type="checkbox" className="checkbox" id="reference-num" />
              <label className="agreementCheck" for="reference-num">
                Any Home Office reference number – to enable the Home Office to
                identify the appropriate file
              </label>
            </li>
            <br />
            <li>
              <input type="checkbox" className="checkbox" id="claim-info" />
              <label className="agreementCheck" for="claim-info">
                Provide any relevant information about the claim itself, such as
                type of asylum application, relevant dates and what it is the
                individual is asking for – e.g., a decision on their asylum
                claim
              </label>
            </li>
            <br />
            <li>
              <input type="checkbox" className="checkbox" id="delays" />
              <label className="agreementCheck" for="delays">
                Details of any issue. For example, if delay:
              </label>
              <ul className="listContainer">
                <li>Explain the period of delay;</li>
                <li>
                  Any efforts by the individual / their lawyer to progress the
                  case or obtain a substantive response direct from the Home
                  Office;
                </li>
                <li>
                  And information about the impact of delay on the individual –
                  e.g., impact on their mental health.
                </li>
                <br />
              </ul>
            </li>
            <li>
              <input type="checkbox" className="checkbox" id="evidence" />
              <label className="agreementCheck" for="evidence">
                Provide any relevant evidence, such as correspondence to and
                from the Home Office to show attempts to resolve the matter
                direct, and / or evidence of impact, such as medical evidence
                relating to impact on mental health or correspondence from
                colleges offering places that cannot be taken up without
                immigration status.
              </label>
            </li>
          </ul>
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
