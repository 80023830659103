import React, { Component } from 'react'
import Home from './intro'
import { getSection } from '../../utils'

export default class Sections extends Component {
  state = {
    stepId: getSection(this.props)
  }

  componentWillReceiveProps = nextProps => {
    this.setState({ stepId: nextProps.match.params.stepId })
  }

  render() {
    return <Home {...this.props} />
  }
}
