import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3a extends Component {
  state = { url: null }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3first' })
      : e.target.value === 'no'
      ? this.setState({ url: '/sections/3/section3aNo' })
      : this.setState({ url: '/sections/3/section3aEndOfRoute' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Step 3</h1>

            <h3>
              Where is my client in the asylum process and how can I help ?
            </h3>
            <form className="form" action="#">
              <p>
                Has the client had a Screening Interview? <br />
                Explain what a Screening Interview is, using the information in
                the Info Box to the right.
                <br />
                Check that they have a copy of the Screening Interview record.
                <br />
              </p>
              <div className="listContainer">
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThree"
                  value="yes"
                  className="stepThreeYes"
                  id="copyOfScreeningYes"
                />{' '}
                <label className="bold agreementCheck" for="copyOfScreeningYes">
                  Yes
                </label>
                <br />
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThree"
                  value="noCopy"
                  className="stepThreeNoCopy"
                  id="noCopy"
                />{' '}
                <label className="bold agreementCheck" for="noCopy">
                  Client says they have had a Screening Interview, but does not
                  have a copy of the Screening Interview record.
                </label>
                <br />
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThree"
                  value="no"
                  className="stepThreeNo"
                  id="copyOfScreeningNo"
                />{' '}
                <label className="bold agreementCheck" for="copyOfScreeningNo">
                  No
                </label>
              </div>
            </form>

            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area">
            <section className="infoBox_step1">
              <h3 className="infoBoxTitle">INFO BOX</h3>
              <article>
                <br />
                <h4>Screening interview</h4>
                <p>
                  A Screening Interview is the initial interview that the UK
                  authorities will normally conduct with someone seeking asylum.
                  It will normally by conducted at the{' '}
                  <strong>Asylum Screening Unit (ASU)</strong>, although it may
                  be conducted by the Immigration Services at an airport or
                  police station in some cases. It will normally involve
                  questions about someone’s identity (name, date of birth and
                  nationality), their close family, their journey to the UK and,
                  very limited questions about, why the person cannot return to
                  their country of origin.
                </p>
                <p>
                  Helpful information about the screening and what to expect can
                  be found{' '}
                  <Slugify
                    url="https://righttoremain.org.uk/toolkit/screening/"
                    isExternal
                    classname="link"
                  >
                    here.
                  </Slugify>
                </p>
              </article>
            </section>
            <section className="infoBox_step1">
              <h3 className="infoBoxTitle">INFO BOX</h3>
              <article>
                <br />
                <h4>Did you claim asylum?</h4>
                <p>
                  If a client has not had a screening interview, it is possible
                  that their asylum claim has not formally been registered. An
                  application should be made as soon as possible and any delay
                  may have a detrimental impact on the case. An asylum
                  application can be made at port on arrival. Otherwise, it is
                  necessary to attend the Asylum Screening Unit.
                  <br />
                  <br />
                  The Home Office website provides information about{' '}
                  <Slugify
                    classname="link"
                    url="https://www.gov.uk/claim-asylum"
                    isExternal
                  >
                    how to claim asylum
                  </Slugify>
                  .<br />
                  You should seek legal advice before claiming asylum.
                  <br />
                  To claim asylum once you are in the UK, you will need to
                  contact the <strong>Asylum Screening Unit</strong> to make an
                  appointment before attending a screening interview. <br />
                  The address is{' '}
                  <strong>
                    Lunar House, 40 Wellesley Road, Croydon CR9 2BY
                  </strong>
                  , Monday to Thursday, 9am to 4:45pm Friday, 9am to 4:30pm.
                  <br />
                  The Asylum Screening Unit (ASU) appointments telephone line is{' '}
                  <i>0208 196 4524</i>
                  .<br />
                </p>
              </article>
            </section>
          </section>
        </section>
      </>
    )
  }
}
export default Section3a
