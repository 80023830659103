import React from 'react'
import Slugify from '../../javascript/slugify'
import { DetentionFlashPoint } from '../../components/Acknowledge/intro'
import fireSymbol from '../../Vector.png'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Fresh Claim/Further submissions</h1>
        <p>
          If your client has exhausted the appeals process, they may be referred
          to as Appeals Rights Exhausted (ARE). If they are unable to return to
          their country of origin, it may be possible for them to make Further
          Submissions (a Fresh Claim for asylum), but in order to do so, they
          need to{' '}
          <span className="bold">
            have information or evidence that has not already been submitted.
          </span>{' '}
        </p>
        <p>
          It is important to understand that it is not possible to make a Fresh
          Claim on the basis that the Home Office and/or the Tribunal simply got
          it wrong. It is necessary to provide evidence or information that was
          not previously available and is significant and material (i.e., it is
          relevant to the reasons the person was not accepted to be a refugee
          and may cause that decision to be reconsidered). This could be new
          evidence that was not available before, relating to the risk they face
          in their country of origin or it could be a change in circumstances in
          their country of origin that means they may now be accepted to be at
          risk. This could take the form of objective evidence such as newspaper
          reports, reports from human rights organisations, or documents
          relating to the client specifically, such as arrest warrants from the
          country of origin, or an expert report on the risk faced by the
          client. <br />
          Alternatively, a Fresh Claim could be based on a change in the UK
          caselaw regarding their country that means they may now be recognised
          to be at risk when they were not before, usually in the form of
          Country Guidance cases –{' '}
          <Slugify
            url="https://www.gov.uk/immigration-asylum-appeal-decisions"
            isExternal
            classname="link"
          >
            these can be searched for on the Tribunal website.
          </Slugify>
          Detailed information on making a Fresh Claim can be found{' '}
          <Slugify
            url="https://www.gov.uk/immigration-asylum-appeal-decisions"
            isExternal
            classname="link"
          >
            here.
          </Slugify>
        </p>

        <p>
          The possibility of making further Submissions/a Fresh Claim are very
          fact specific and it is very important that{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice
          </Slugify>
          is sought. If it is possible for a fresh claim to be made, then{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            Legal Aid
          </Slugify>
          is available for any fresh asylum claim. Further Submissions need to
          be lodged at the Further Submissions Unit in Liverpool. More
          information about how to do this can be found{' '}
          <Slugify
            url="https://www.gov.uk/submit-new-evidence-asylum-claim"
            isExternal
            classname="link"
          >
            here.
          </Slugify>
          You may need to telephone to make an appointment and lodge the
          paperwork in person. It is important to note that Further Submissions
          are can be refused.
        </p>
        <p>
          A decision is likely to be received in writing to the client's last
          address a number of weeks or months after the appointment. In some
          cases, clients may receive a{' '}
          <Slugify
            url={'/sections/3/section3dEndOfRoute'}
            classname="legal link"
          >
            positive decision granting them asylum.
          </Slugify>
          In other cases, clients may receive a refusal with a right of appeal.
          In this case, clients would appeal it at the First Tier Tribunal{' '}
          <Slugify url="/sections/3/section3e" classname="legal link">
            click here
          </Slugify>
          for information on how to support your client at this stage. It is
          crucial that your client secure{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice
          </Slugify>
          at this point.
        </p>
        <p>
          In other cases, the Home Office may refuse the Further Submissions
          with no right of appeal, because they do not accept the evidence and
          information amounts to a Fresh Claim. In this situation, the client
          would remain ARE. There may be an avenue to challenge the decision by
          way of judicial review, but the client may be at particular risk of
          detention following the refusal of a fresh claim. It is, therefore,
          essential{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice
          </Slugify>
          is sought urgently.
        </p>
        <p>
          <h4>The right to private and family life / long residency</h4>
          It may be that someone does not have an asylum claim, but they have
          established strong ties to the UK during the time they have spent
          here, for example entering into a relationship and / or having
          children. It may be possible to make an application for permission to
          stay in the UK on this basis. It is important for{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice
          </Slugify>
          to be sought. There may be others reasons why an individual cannot
          return to their country of origin, cannot leave the UK or should be
          given permission to remain in the UK and legal advice should be sought
          on the specific facts of an individual’s case. If they have no
          applications or appeals outstanding, they will be liable to detention
          and removal.
        </p>
        <form className="form" action="#">
          <section className="next-noRatio">
            <Slugify url={'/'} classname="NextButton">
              Fresh start
            </Slugify>
          </section>
        </form>
        <br />
        <br />
      </section>
      <section className="aside_Info_Area">
        <DetentionFlashPoint />
        <aside className="aside_flashpoint">
          <div className="gridContainer">
            <img className="grid-fire " src={fireSymbol} alt="" />
            <h3 className="grid-titles">
              Practical support for clients who are Appeals Rights
            </h3>
          </div>
          <p>
            This can be a very difficult time, as clients continue to be
            required to report to the Immigration Service as per their bail
            conditions/reporting restrictions and will be treated as an
            absconder if they{' '}
            <Slugify
              url="https://www.gov.uk/immigration-reporting-centres"
              isExternal
              classname="link"
            >
              do not report as required,
            </Slugify>
            but they are at risk of detention when they report as they have no
            application outstanding. It can also be a difficult time as the
            financial support and/or accommodation provided on the basis of any
            asylum application will be terminated. This can mean that people
            become destitute and/or homeless. This can make people vulnerable to
            exploitation and can exacerbate any existing mental health problems
            or other vulnerabilities.
          </p>
          <p>
            There are some charitable options that can support clients to access
            some income/housing/food parcels and so on. Check for local support
            services, including churches, synagogues and mosques.
            Accommodation/hosting providers nationally can be found{' '}
            <Slugify url="https://naccom.org.uk/" isExternal classname="link">
              here
            </Slugify>{' '}
            and information about some charitable grants can be found{' '}
            <Slugify
              isExternal
              classname="link"
              url="https://www.turn2us.org.uk/"
            >
              here.
            </Slugify>
          </p>
          <p>
            Access to{' '}
            <Slugify
              isExternal
              classname="link"
              url="https://www.bma.org.uk/media/1838/bma-refugee-and-asylum-seeker-health-resource-june-19.pdf"
            >
              healthcare
            </Slugify>
            and education will be further restricted and clients may be subject
            to charges for accessing healtcare or will otherwise be treated as
            having a debt to the NHS which could then impact future immigration
            applications. Clients are still barred from any form of employment,
            including some voluntary work.
          </p>
          <p>
            If further submissions are made, a further application for asylum
            support and/or accommodation may be made. If further submissions
            cannot be made, it may still be possible to make a further
            application for asylum support and/or accomodation in some
            circumtances, where an asylum seeker is: taking all reasonable steps
            to leave the UK; unable to leave the UK because they are typically
            unable to travel or as a result of a medical issue; there is no
            viable route of return (in the opinion of the UK government); a
            judicial review has been lodged and permission to review has been
            granted.
          </p>
          <p>
            To find out more about the possibility for applying for asylum
            support at this stage,{' '}
            <Slugify
              url="https://www.asaproject.org/resources"
              isExternal
              classname="link"
            >
              click here
            </Slugify>
            for useful factsheets from the Asylum Support Appeals Project
            (ASAP).
          </p>
        </aside>
      </section>
    </section>
  </>
)
