import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'
import './Index.css'

const InfoBox = () => (
  <>
    <section className="aside_Info_Area">
      <section className="infoBox_step1">
        <h3 className="infoBoxTitle">INFO BOX</h3>
        <article>
          <br />
          <h4>
            <b>&#9312;</b> What is asylum/claiming protection?
          </h4>
          <p>
            An asylum seeker is seeking international protection, based on the
            categories of the 1951 Refugee Convention. They are people who owing
            to a well-founded fear of being persecuted for reasons of race,
            religion, nationality, membership of a particular social group or
            political opinion, are outside the country of their nationality and
            are unable or, owing to fear, unwilling to ask for the protection of
            the country of their nationality: this includes people who have been
            stripped of their nationality.
          </p>
          <p>
            If an individual is found to face a serious risk of harm in their
            country of nationality, but not for one of the Convention reasons,
            they may be granted Humanitarian Protection (for example, widespread
            war).
          </p>
          <br />
        </article>
        <article>
          <h4>What is not asylum?</h4>
          <p>
            Even though it can seem unfair that someone may be separated from
            their family and friends, or made to leave the country when they are
            ill, if they do not fear harm in their country of origin, they are
            not able to claim asylum. There may be other ways for them to remain
            in the country through securing other types of leave to remain.
          </p>
        </article>
      </section>
    </section>
  </>
)

const urls = {
  next: '/sections/3/section3a',
  endOfRoute: '/sections/1/section1EndOfRoute',
  checkList: '/sections/3/section3a'
}

function isFormReady(state) {
  const responses = Object.keys(state)
  return (
    responses.includes('question1') &&
    responses.includes('question2') &&
    responses.includes('question3')
  )
}

function getURL(state) {
  const { question1, question2, question3 } = state
  if (
    question1 === undefined ||
    question2 === undefined ||
    question3 === undefined
  ) {
    return null
  }
  if (question1 === 'yes' && question2 === 'yes' && question3 === 'yes') {
    // console.log('all q were yes')
    return urls.checkList
  }
  if (question1 === 'no' && question2 === 'no' && question3 === 'no') {
    // console.log('all q were no')
    return urls.endOfRoute
  }
  if (question3 === 'yes') {
    // console.log('q3 was yes')
    return urls.endOfRoute
  }
  // console.log('was not in the first 2 cases, so returning next')
  return urls.next
}

export default class Intro extends Component {
  state = {}
  handleRadioSelect = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const ready = isFormReady(this.state)
    const url = getURL(this.state, { ready })

    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Step 1</h1>
            <div className="infoBox_Index">
              <h2>
                Do you want to claim asylum? <b>&#9312;</b>
              </h2>
            </div>
            <form className="form" action="#">
              <p>Are you unable to return to your country of origin ?</p>
              <div className="listContainer">
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question1"
                  value="yes"
                  checked={this.state.question1 === 'yes'}
                  className="nextYes"
                  id="question1Yes"
                />
                <label className="agreementCheck" for="question1Yes">
                  Yes
                </label>
                <br />
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question1"
                  value="no"
                  checked={this.state.question1 === 'no'}
                  className="nextNo"
                  id="question1No"
                />
                <label className="agreementCheck" for="question1No">
                  No
                </label>
              </div>

              <p>Are you at risk in your country of origin ?</p>
              <div className="listContainer">
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question2"
                  checked={this.state.question2 === 'yes'}
                  value="yes"
                  id="question2Yes"
                />
                <label className="agreementCheck" for="question2Yes">
                  Yes
                </label>
                <br />
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question2"
                  value="no"
                  checked={this.state.question2 === 'no'}
                  className="nextNo"
                  id="question2No"
                />
                <label className="agreementCheck" for="question2No">
                  No
                </label>
                <br />
              </div>
              <p>
                Are there any other reasons you are unable to return to your
                country of origin or otherwise leave the UK? For example, do you
                have children or family members here, or is your health poor?
              </p>
              <div className="listContainer">
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question3"
                  checked={this.state.question3 === 'yes'}
                  value="yes"
                  id="question3Yes"
                />
                <label className="agreementCheck" for="question3Yes">
                  Yes
                </label>
                <br />
                <input
                  onChange={this.handleRadioSelect}
                  type="radio"
                  name="question3"
                  value="no"
                  checked={this.state.question3 === 'no'}
                  className="nextNo"
                  id="question3No"
                />
                <label className="agreementCheck" for="question3No">
                  No
                </label>
                <br />
                <br />
              </div>
            </form>
            <section className="next">
              <Slugify
                url={url ? url : '#'}
                classname={url ? 'NextButton' : 'NextButtonPassive'}
              >
                Next
              </Slugify>
            </section>
          </section>
          <InfoBox />
        </section>
      </>
    )
  }
}
