import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Help your client to prepare for the substantive interview</h1>
        <p>
          <Slugify url="/sections/3/section3secondEndOfRoute" classname="link">
            Ensure that your client has asylum support.
          </Slugify>
          <br />
          If your client needs is curious about accessing education, or other
          rights, help them do so, using this{' '}
          <Slugify
            classname="link"
            isExternal
            url="https://themigrantslawproject.org/wp-content/uploads/2018/02/Rights-and-restrictions-reformatted.pdf"
          >
            guide to their entitlements.
          </Slugify>
          <br />
          Encourage your client to come back to you if there are problems with
          their asylum support so that you can help them.
        </p>
        <p>
          Ensure they have{' '}
          <Slugify classname="link" url="/legalaid">
            a lawyer.
          </Slugify>
          <ul className="listContainer">
            <li>ensure evidence is gathered and translated, as necessary</li>
            <li>
              ensure that they are able to put forward their account confidently
            </li>
            <li>ensure that they have requested an interpreter</li>
            <li>
              ensure that they have requested the interview be audio-recorded–
              it should be possible to make this request by emailing the address
              on the interview letter
            </li>
            <li>
              ensure that they know to raise any concerns about interpretation /
              understanding the interpreter during the interview
            </li>
          </ul>
        </p>
        <p>
          If there has been a significant delay, it is important to check that
          the client has not moved address without informing the Home Office –
          sometimes, people think that if they are moved from one address in
          asylum accommodation to another, the Home Office will be informed by
          the Asylum Support department, but this is not the case and it is
          still important to inform the Home Office.
        </p>
        <p>
          An individual who has been waiting for their substantive interview for
          more than 6 months can speak to their MP about the delay - we have a
          guide for making a{' '}
          <Slugify classname="link" url={'/mp-checklist'}>
            referral here.
          </Slugify>
        </p>
        <p>
          There is a{' '}
          <Slugify
            url="https://righttoremain.org.uk/toolkit/asylumiv/"
            isExternal
            classname="link"
          >
            useful guide on preparing for a substantive interview here
          </Slugify>
          , which you should share with your client.
        </p>
        <section className="next-noRatio">
          <Slugify classname="NextButton" url={'/sections/3/section3b'}>
            Next
          </Slugify>
        </section>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
