import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3second extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3b' })
      : this.setState({ url: '/sections/3/section3secondEndOfRoute' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Is your client accessing asylum support?</h1>
            <form className="form" action="#">
              <h4>
                Does your client have somewhere to live, and do they have some
                income?
              </h4>
              <h4>
                Are they living in a hostel? Do they have an Aspen Card? Do they
                get £35 a week?
              </h4>
              <br />
              <div className="listContainer">
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeB"
                  value="yes"
                  id="stepThreeBYes"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeBYes">
                  Yes
                </label>
                <br />
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeB"
                  value="no"
                  id="stepThreeBNo"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeBNo">
                  No
                </label>
                <br />
              </div>
            </form>
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area">
            <section className="infoBox_step1">
              <h3 className="infoBoxTitle">INFO BOX</h3>
              <article>
                <br />
                <h4>What is asylum support? </h4>
                <p>
                  Many asylum seekers do not access the right forms of support -
                  they can be afraid that asking for financial support may
                  impact on their claim.
                </p>
                <p>
                  Asylum seekers are generally barred from working and are not
                  able to claim mainstream welfare benefits.
                </p>
                <p>
                  If they have no savings, and are destitute, they are eligible
                  for accommodation and a payment of £35 per person per week,
                  through a pre-paid card, sometimes called an{' '}
                  <Slugify
                    classname="link"
                    url="https://www.gov.uk/asylum-support/what-youll-get"
                    isExternal
                  >
                    Aspen Card.
                  </Slugify>
                  If they are living with friends, then they may still be able
                  to claim some financial support. You can help them to do this
                  without being a qualified lawyer.
                </p>
                <p>
                  There is more information on asylum seekers’ rights and
                  restrictions{' '}
                  <Slugify
                    classname="link"
                    isExternal
                    url="https://themigrantslawproject.org/wp-content/uploads/2018/02/Rights-and-restrictions-reformatted.pdf"
                  >
                    here.
                  </Slugify>
                  It can be a good idea to go through this with clients to make
                  sure that they have all the information they need about what
                  they can and cannot do.
                </p>
                <p>
                  For support in making an asylum support claim click{' '}
                  <Slugify
                    classname="link"
                    url="/sections/3/section3secondEndOfRoute"
                  >
                    here.
                  </Slugify>
                </p>
              </article>
            </section>
          </section>
        </section>
      </>
    )
  }
}
export default Section3second
