import React, { Fragment } from 'react'
import './Main.css'
import Router from '../../Router'

export default () => (
  <Fragment>
    <section className="content_main">
      <Router />
    </section>
  </Fragment>
)
