import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 3</h1>
        <h3>Finding Legal Representation</h3>
        <p>
          Your client should obtain legal advice and representation on the
          asylum process. If they cannot afford to pay a lawyer, they may be
          able to access free legal advice and representation. More details are{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            available here.
          </Slugify>
        </p>
        <p>
          Details of local legal aid solicitors can be found{' '}
          <Slugify
            classname="link"
            url="https://find-legal-advice.justice.gov.uk/"
            isExternal
          >
            here.
          </Slugify>
        </p>
        <p>
          Details of local Law Centres, who can provide free advice and
          representation, can be found{' '}
          <Slugify
            classname="link"
            url="https://www.lawcentres.org.uk/about-law-centres/law-centres-on-google-maps/alphabetically"
            isExternal
          >
            here.
          </Slugify>
        </p>
        <p>
          It is a good idea to make sure that you have good contacts with local
          law firms and Law Centres.
        </p>
        <p>
          <strong>
            We strongly recommend that individuals are represented throughout
            the asylum process.
          </strong>{' '}
          We have provided a pro forma to support you in making a{' '}
          <Slugify url={'/referrals/solicitor'} classname="legal link">
            successful referral to a solicitor.
          </Slugify>
          There are lots of things you need to do to help client, but one of the
          most important is accessing legal advice and representation where
          possible -{' '}
          <Slugify url={'/'} classname="legal link">
            keep trying!
          </Slugify>
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
