import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Helping a client prepare for their substantive interview</h1>
        <p>
          Ensure that your client has{' '}
          <Slugify
            url="/sections/3/section3secondEndOfRoute"
            classname="link"
            isExternal
          >
            asylum support.
          </Slugify>
          If your client needs is curious about accessing education, or other
          rights, help them to do so, using this guide to their entitlements.
          Encourage your client to come back to you if there are problems with
          their asylum support so that you can help them. Ensure they{' '}
          <Slugify url={'/legalaid'} classname="link" isExternal>
            have a lawyer.
          </Slugify>
          <br />
          <br />
          <ul className="bullet listContainer">
            <li className="bullet">
              ensure evidence is gathered and translated, as necessary
            </li>
            <li className="bullet">
              ensure that they are able to put forward their account
            </li>
            <li className="bullet">
              ensure that they have requested an interpreter
            </li>
            <li className="bullet">
              ensure that they have requested the interview be audio-recorded–
              it should be possible to make this request by emailing the address
              on the interview letter
            </li>
            <li className="bullet">
              ensure that they know to raise any concerns about interpretation /
              understanding the interpreter during the interview
            </li>
          </ul>
        </p>
        <p>
          If there has been a significant delay, it is important to check that
          the client has not moved address without informing the Home Office –
          sometimes, people think that if they are moved from one address in
          asylum accommodation to another, the Home Office will be informed by
          the Asylum Support department,{' '}
          <strong>but this is not the case</strong> and it is still important to
          inform the Home Office. <br />
          <br />
          An individual who has been waiting for their substantive interview for
          more than 6 months can speak to their MP about the delay - we have a
          guide for making a{' '}
          <Slugify url={'/mp-checklist'} classname="link" isExternal>
            referral here.
          </Slugify>
          <br />
          <br />
          There is a{' '}
          <Slugify
            url="https://righttoremain.org.uk/toolkit/asylumiv/"
            classname="link"
            isExternal
          >
            useful guide on preparing for a substantive interview here,
          </Slugify>
          which you should share with your client.
          <br />
          Make sure that they understand to ask for a{' '}
          <strong>audio recording </strong>- it should be possible to make this
          request by emailing the address on the email letter.Continue to
          support the client with any issue arising from accessing asylum
          support and ensure that they are reporting, and their lawyer is
          engaging
        </p>
        <p></p>
      </section>
      <section className="aside_Info_Area">
        <section className="infoBox_step1">
          <h3 className="infoBoxTitle">INFO BOX</h3>
          <article>
            <br />
            <h4>What is the Substantive Interview?</h4>
            <p>
              The Substantive interview is one of the most important stages of
              the asylum process, where clients get to set out the basis of
              their asylum claim. It is an interview, which can last for several
              hours. It is important to be prepared, and for the client to be
              able to set out their account as clearly as possible.
              <strong>
                It is very important that legal advice be taken in preparing for
                this interview.
              </strong>
              <br />
              <br />
              <Slugify
                url="https://righttoremain.org.uk/toolkit/asylumiv/"
                classname="link"
                isExternal
              >
                The Asylum Interview guide
              </Slugify>
            </p>
          </article>
        </section>
      </section>
    </section>
  </>
)
