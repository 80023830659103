import React from 'react'
import { Route } from 'react-router-dom'
import Navigator from '../components/Navigator'

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <div>
          <Navigator {...props} />
          <Component {...props} />
        </div>
      )
    }}
  />
)
