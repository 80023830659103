import React, { Fragment } from 'react'

export default () => (
  <Fragment>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 3.e</h1>
        <h3>End of route </h3>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </Fragment>
)
