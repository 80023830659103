import React from 'react'
import Slugify from '../../javascript/slugify'

import fireSymbol from '../../Vector.png'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Supporting your client after the substantive interview</h1>

        <p>
          <span class="bold">
            There are ways that you can support your client after this
            interview. Review the interview record and the audio recording of
            the interview with them and address any issues arising urgently -
            examples could be that the client did not understand the
            interpreter, that they felt they did not understand a question, or
            that there is something they forgot to mention.
          </span>
        </p>
        <p>
          If the client has a lawyer, it is very important they discuss their
          interview record with their lawyer urgently following the interview.
          If the client does not have a lawyer, you should support them in{' '}
          <Slugify url={'/legalaid'} classname="link">
            finding a lawyer
          </Slugify>
          .
        </p>
        <p>
          If the client does not have a lawyer and has any concerns about the
          interview or interview record, the client could write to the Home
          Office informing them they are concerned about the interview /
          interview record and asking the Home Office not decide their case,
          save for a grant of refugee status, until they have been able to
          obtain legal advice and make submissions regarding the interview.
        </p>
        <p>
          If the client cannot find a legal representative to take their case,
          they may be able to find a legal adviser able to discuss their
          concerns regarding the interview and to assist the client to make
          representations at a drop-in advice centre.
        </p>
        <p>
          <Slugify url="/sections/3/section3secondEndOfRoute" classname="link">
            {' '}
            You should continue to support your client to access asylum support,
          </Slugify>
          and is accessing relevant services.
        </p>
        <section className="next-noRatio">
          <Slugify classname="NextButton" url={'/sections/3/section3d'}>
            Next
          </Slugify>
        </section>
      </section>
      <section className="aside_Info_Area">
        <aside className="aside_flashpoint">
          <div className="gridContainer">
            <img className="grid-fire " src={fireSymbol} alt="" />
            <h3 className="grid-titles">SIGNIFICANT DELAY </h3>
          </div>
          <p>
            If there has been a significant delay in receiving the decision
            after the interview, it is important to check that the client has
            not moved address without informing the Home Office – sometimes,
            people think that if they are moved from one address in asylum
            accommodation to another, the Home Office will be informed by the
            Asylum Support department, but this is not the case and it is still
            important to inform the Home Office.
          </p>
          <p>
            If someone has been waiting a long time for a decision, it would be
            worth speaking to their lawyer about the possibility of challenging
            the delay, particularly if the delay is causing them specific
            difficulties (such as accessing services or impacting their mental
            health). The period of 12 months is often seen to be the minimum
            before delay can be challenged, but the issue is really the impact
            on the client. If the lawyer does not think it appropriate to
            challenge, it may be worth the client approaching their MP to ask
            they intervene by contacting the Home Office about the delay. We
            have a guide for making a{' '}
            <Slugify url={'/mp-checklist'} classname="link">
              referral here.
            </Slugify>
          </p>
        </aside>
      </section>
    </section>
  </>
)
