import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3e extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3dEndOfRoute' })
      : this.setState({ url: '/sections/3/section3f' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Appealing a negative initial asylum decision</h1>
            <p>
              The First Tier Tribunal (Immigration and asylum Chamber) is the
              Tribunal that will hear (consider) any appeal against the decision
              of the Home Office to refuse asylum. You should have been provided
              with forms to appeal with the initial decision letter, also
              available{' '}
              <Slugify
                isExternal
                classname="link"
                url="https://www.gov.uk/government/publications/appeal-a-visa-or-immigration-decision-within-the-uk-form-iaft-1"
              >
                here
              </Slugify>
              . You can use these forms to lodge your appeal. Find out more
              about the First Tier Tribunal{' '}
              <Slugify
                isExternal
                classname="link"
                url="https://themigrantslawproject.org/wp-content/uploads/2017/02/FTT-Procedure-reformatted.pdf"
              >
                here,
              </Slugify>
              and about the overall court structure{' '}
              <Slugify
                isExternal
                classname="link"
                url="https://themigrantslawproject.org/wp-content/uploads/2017/02/Immigration-legal-system.pdf"
              >
                here.
              </Slugify>
            </p>
            <p>
              There will normally be a deadline for appealing indicated in the
              documents that tell you that you have a negative decision - it is
              crucial that you lodge your appeal form with the Tribunal before
              this deadline otherwise you may lose the right to appeal. Failing
              to comply with the deadline may also mean that asylum Support is
              stopped. It is crucial that{' '}
              <Slugify url={'/legalaid'} classname="legal link">
                legal advice
              </Slugify>
              is secured if at all possible.
            </p>
            <p>
              Once an appeal has been lodged, the Tribunal will write to provide
              information regarding the hearing (normally a pre-hearing / case
              management hearing to discuss the logistics of the full hearing;
              and a full hearing - usually 2-3 hours - at which the case will be
              considered in detail) and documentation to be provided to the
              Tribunal. If an individual has a lawyer, they should discuss any
              correspondence with their lawyer. The time between an appeal
              lodged and a case being listed for hearing and being heard varies,
              but it would normally be expected that an asylum appeal be listed
              within a month or so of the appeal being lodged.
            </p>
            <p>
              At the full hearing, the client will usually required to give
              evidence, which involve answering questions from their legal
              representative, the Home Office representative and the Judge. Any
              witnesses may also be required to give evidence and questioned. If
              the Tribunal is informed an interpreter is needed, an interpreter
              will be presentation to in interpret to and for the Appellant and
              / or any witnesses, as necessary. Useful detailed information can
              be found here:{' '}
              <Slugify
                url={'https://righttoremain.org.uk/toolkit/appeal/'}
                classname="link"
                isExternal
              >
                https://righttoremain.org.uk/toolkit/appeal/
              </Slugify>
            </p>
            <p>
              Once an appeal has been heard in full, a determination should be
              received from the Tribunal within 6-8 weeks of the hearing. An
              appeal can be allowed (granted) or dismissed (refused). It is
              essential that{' '}
              <Slugify url="/legalaid" classname="link">
                legal advice
              </Slugify>{' '}
              is sought urgently following receipt of a determination,
              particularly if they appeal has been dismissed.
            </p>
            <p>
              You client can continue to access asylum support at this time.{' '}
              <Slugify
                url="/sections/3/section3secondEndOfRoute"
                classname="link"
              >
                Find out how to support them here.
              </Slugify>
            </p>
            <br />
            <article>
              <form className="form" action="#">
                <h3>Decision of the Appeal: First tier tribunal decision</h3>
                <div className="listContainer">
                  <input
                    onClick={this.handleLink}
                    type="radio"
                    name="stepThreeE"
                    value="yes"
                    className="stepThreeEYes"
                    id="stepThreeEYes"
                  />{' '}
                  <label className="bold agreementCheck" for="stepThreeEYes">
                    Positive Decision (nb: the Home Office can appeal a positive
                    decision of the Tribunal. It may be a number of weeks after
                    you receive the decision of the court before you will know
                    for sure that the Home Office has appealed or will grant you
                    Leave)
                  </label>
                  <br />
                  <br />
                  <input
                    onClick={this.handleLink}
                    type="radio"
                    name="stepThreeE"
                    value="no"
                    className="stepThreeENo"
                    id="stepThreeENo"
                  />{' '}
                  <label className="bold agreementCheck" for="stepThreeENo">
                    Negative Decision
                  </label>
                  <br />
                </div>
              </form>
            </article>
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area" />
        </section>
      </>
    )
  }
}
export default Section3e
