import React, { Component } from 'react'
import { getSection } from '../../utils'

import Home from './intro'

export default class Sections extends Component {
  state = {
    stepId: getSection(this.props)
  }

  componentWillReceiveProps = nextProps => {
    this.setState({ stepId: nextProps.match.params.stepId })
  }

  render() {
    const { stepId } = this.state
    if (stepId) {
      return <Home {...this.props} />
    }
  }
}
