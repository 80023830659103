import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { initGA } from './Tracking/index'
import ReactGA from 'react-ga'

initGA(process.env.REACT_APP_TRACKING_ID)
ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(<App />, document.getElementById('root'))
