// notes slide 28 -> slide 6

import React from 'react'
import './asylumSupport.css'
export default props => {
  return (
    <section className="legalAidPage">
      <aside>
        <i
          onClick={() => props.history.goBack()}
          className="hideLegalAidBtn fa-3x fas fa-times-circle"
        />
      </aside>

      <h1>Asylum Support</h1>
      <section className="legalAidPageScope">
        <article className="article articleScope">
          <h4>
            Key documents that clients should have if they have claimed asylum
          </h4>
          <p>
            Having the correct documentation is crucial for asylum seekers, as
            they may be liable to detention without them. The most important is
            the ARC card. If the client has lost their ARC or they have
            definitely claimed asylum, but have not yet received an ARC (e.g.,
            they have a copy of their screening interview record or other
            correspondence from the Home Office confirming they have claimed
            asylum), they may have difficulties evidencing their identity and /
            or accessing support and services to which they are entitled as an
            asylum seeker.
          </p>
        </article>
        <article className="article">
          <h4>Contact Migrant Help to let them know</h4>
          <p>
            You’ll need to give them your Home Office or port reference number.
            <br />
            <br />
            <strong>Migrant Help</strong>
            <br />
            <br />
            Telephone: <span className="bold">0808 800 0630</span>
            <br />
            Monday to Friday, 9:30am to 3pm
            <br />
            <br />
            They should also have a document that informs them of the conditions
            of the Immigration Bail (previously known as Temporary Admission)
            including any reporting restrictions.
          </p>
        </article>
      </section>
    </section>
  )
}
