import React from 'react'

export default () => {
  return (
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Making a successful referral to a solicitor</h1>
        <h2>checklist</h2>
        <p>
          When supporting an asylum seeker, you may need to make a referral to a
          solicitor to ensure that they have legal representation to take their
          claim forwards. In order to make the process as smooth as possible,
          and maximise chances of a successful referral, there are key pieces of
          information you should include:
        </p>
        <p>
          Client name and Date of Birth Client address: Client’s nationality
          Date when client entered the UK (may be approximate): Basis on which
          client entered the UK [i.e. to claim asylum, or did they enter as a
          student and now wish to claim asylum/have claimed asylum] Date when
          client claimed asylum [if they have – if they have not yet, explain
          this]
        </p>
        <ul className="listContainer noBullets">
          <li>
            <input
              type="checkbox"
              name="deadlines"
              value="deadlines"
              id="deadlines"
            />{' '}
            <label for="deadlines">Are there any deadlines coming up?</label>
          </li>
          <li>
            <input
              type="checkbox"
              name="screening"
              value="screening"
              id="screening"
            />{' '}
            <label for="screening">
              Have they been to the Asylum Screening Unit?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="interview"
              value="interview"
              id="interview"
            />{' '}
            <label for="interview">
              Have they had a substantive asylum interview?
            </label>
          </li>
          <li>
            <input type="checkbox" name="appeal" value="appeal" id="appeal" />{' '}
            <label for="appeal">Have they had an asylum appeal?</label>
          </li>
          <li>
            <input
              type="checkbox"
              name="appeals-count"
              value="appeals-count"
              id="appeals-count"
            />{' '}
            <label for="appeals-count">How many appeals have they had?</label>
          </li>
          <li>
            <input
              type="checkbox"
              name="last-appeal"
              value="last-appeal"
              id="last-appeal"
            />{' '}
            <label for="last-appeal">When was their last asylum appeal?</label>
          </li>
          <li>
            <input
              type="checkbox"
              name="last-activity"
              value="last-activity"
              id="last-activity"
            />{' '}
            <label for="last-activity">
              When was the last activity / event in their case?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="fresh-claims"
              value="fresh-claims"
              id="fresh-claims"
            />{' '}
            <label for="fresh-claims">
              Have they made any fresh asylum claims / submitted any further
              submissions?
            </label>
          </li>
          <li>
            <input type="checkbox" name="delays" value="delays" id="delays" />{' '}
            <label for="delays">
              If there are on-going delays in their case, is this having any
              demonstrable impact on them? If so, what?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="uk-family"
              value="uk-family"
              id="uk-family"
            />{' '}
            <label for="uk-family">
              Do they have any family or close ties in the UK? If so, what is
              the nationality and / or immigration status of their family
              members? What are the ages of any children?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="criminal-convictions"
              value="criminal-convictions"
              id="criminal-convictions"
            />{' '}
            <label for="criminal-convictions">
              Do they have any criminal convictions?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="new-changes"
              value="new-changes"
              id="new-changes"
            />{' '}
            <label for="new-changes">
              Has anything changed since the last decision in their case / their
              case concluded? If so, do they have any evidence relating to the
              change?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="other-reasons"
              value="other-reasons"
              id="other-reasons"
            />{' '}
            <label for="other-reasons">
              Is there any other reason they are unable to return to their
              country of origin / are unable to leave the UK?
            </label>
          </li>
          <li>
            <input
              type="checkbox"
              name="financial-support"
              value="financial-support"
              id="financial-support"
            />{' '}
            <label for="financial-support">
              Do they have financial support and / or accommodation?
            </label>
          </li>
        </ul>
        <p>
          The issue that you are seeking help with: e.g. advice before claiming
          asylum, representation in an appeal, etc. Your contact details.
        </p>
      </section>
      <section className="aside_Info_Area"></section>
    </section>
  )
}
