import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'
import fireSymbol from '../../Vector.png'

const FirstSafeCountries = () => {
  return (
    <div>
      People seeking asylum are expected to do so in the first safe country they
      reach. If someone has travelled through a safe third country (a country
      that is not their country of origin or the UK), this may impact any asylum
      claim they seek to make in the UK. If the country they travelled through
      is inside Europe, it is likely the UK authorities will seek to remove them
      to that European country for their claim for asylum to be processed there,
      subject to any arguments against such removal. This is often referred to
      as a ‘Third country removal’ or a ’Dublin removal’. If a client is at risk
      of removal to another European country,{' '}
      <strong>it is crucial that they seek legal advice immediately,</strong>{' '}
      providing any information and evidence about why they cannot return to
      that country to have their asylum claim processed there to their lawyer.
      If the country they travelled through is outside Europe, it may impact the
      admissibility of their asylum claim in the UK – see{' '}
      <Slugify
        url={
          'https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/746565/Inadmissibility-guidance-v1.0ext.pdf'
        }
        isExternal
        classname="link"
      >
        the Home Office Guidance: <br />
      </Slugify>{' '}
      <br />
      There may be situations which allow for an asylum seeker in the UK to
      argue against such a removal, such as the presence of an unaccompanied
      minor or other dependant. There is also a provision for exceptional
      humanitarian grounds. If you think this may apply to your client is it
      crucial that they seek legal advice.
    </div>
  )
}

class Section1Next extends Component {
  state = {
    url: null
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/2' })
      : this.setState({ url: '/sections/1/section1SecondQuestionEndOfRoute' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Step 1</h1>
            <form className="form" action="#">
              <p>Have you asked the government for protection ?</p>
              <div>
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="answer"
                  value="yes"
                  id="answerYes"
                />
                <label className="agreementCheck" for="answerYes">
                  Yes
                </label>
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="answer"
                  value="no"
                  id="answerNo"
                />
                <label className="agreementCheck" for="answerNo">
                  No
                </label>
                <br />
              </div>
            </form>
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area">
            <aside className="aside_flashpoint">
              <div className="gridContainer">
                <img className="grid-fire " src={fireSymbol} alt="" />
                <h3 className="grid-titles">FIRST SAFE COUNTRIES</h3>
              </div>
              <FirstSafeCountries />
            </aside>
          </section>
        </section>
      </>
    )
  }
}

export default Section1Next
