import React, { Component } from 'react'
import { getSection } from '../utils'

// import SectionIntro from './Sections/SectionIntro'
import Section1 from './Section1/intro'
import Section2 from './Section2/intro'
import Section3 from './Section3/intro'
import Section4 from './Section4/intro'
import GlossaryBox from '../components/GlossaryBox'
import Home from './intro'

export default class Sections extends Component {
  state = {
    section: getSection(this.props)
  }

  render() {
    const { section } = this.state
    switch (section) {
      case '1':
        return <Section1 />
      case '2':
        return <Section2 />
      case '3':
        return <Section3 />
      case '4':
        return <Section4 />
      case 'glossary':
        return <GlossaryBox />
      default:
        return <Home {...this.props} />
    }
  }
}
