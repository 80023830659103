import React, { useEffect, useState } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'
import Header from './components/Header'
import Main from './components/Main'
import './App.css'
import './index.css'
import logo from './components/Navigator/logo.png'

export const Movileview = () => {
  return (
    <div>
      <Header />
      <div>
        <span className="MobileView_title">Asylum Law Guide</span>
      </div>
      <div>
        <div className="MobileView">
          <span>
            You can only access this service on larger devices - such as
            desktops, laptops and tablets. It's important information that is
            easier to read that way.
            <br /> <br />
            Thank you.
            <section className="logo_mobileview">
              <a href="https://themigrantslawproject.org">
                <img className="nav_logo" src={logo} alt="" />
              </a>
            </section>
          </span>
        </div>
      </div>
    </div>
  )
}

const App = () => {
  const mobileViewMatch = window.matchMedia('(max-width: 650px)')
  const [isMobile, setIsMobile] = useState(mobileViewMatch.matches)

  useEffect(() => {
    const handler = e => setIsMobile(e.matches)

    mobileViewMatch.addListener(handler)
    return () => mobileViewMatch.removeListener(handler)
  }, [mobileViewMatch])

  return isMobile ? (
    <Movileview />
  ) : (
    <Router>
      <Header />
      <section className="info_main">
        <Main />
      </section>
    </Router>
  )
}

export default App
