import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Making a successful referral to a solicitor: Checklist</h1>

        <p>
          When supporting an asylum seeker, you may need to make a referral to a
          solicitor to ensure that they have legal representation to take their
          claim forwards. In order to make the process as smooth as possible,
          and maximise chances of a successful referral, there are key pieces of
          information you should include:
        </p>
        <p>
          <ol className="bullet">
            <li className="bullet">Client name and Date of Birth</li>
            <li className="bullet">Client address</li>
            <li className="bullet">Client’s nationality</li>
            <li className="bullet">
              Date when client entered the UK (may be approximate)
            </li>
            <li className="bullet">
              Basis on which client entered the UK [i.e. to claim asylum, or did
              they enter as a student and now wish to claim asylum/have claimed
              asylum]
            </li>
            <li className="bullet">
              Date when client claimed asylum [if they have – if they have not
              yet, explain this]
            </li>
            <li className="bullet">Current status of asylum claim:</li>
          </ol>
          <li className="bulletInBullet">Are there any deadlines coming up?</li>
          <li className="bulletInBullet">
            Have they been to the Asylum Screening Unit?
          </li>
          <li className="bulletInBullet">
            Have they had a substantive asylum interview?
          </li>
          <li className="bulletInBullet">Have they had an asylum appeal?</li>
          <li className="bulletInBullet">How many appeals have they had?</li>
          <li className="bulletInBullet">When was their last asylum appeal?</li>
          <li className="bulletInBullet">
            When was the last activity / event in their case?{' '}
          </li>
          <li className="bulletInBullet">
            Have they made any fresh asylum claims / submitted any further
            submissions?
          </li>
          <li className="bulletInBullet">
            If there are on-going delays in their case, is this having any
            demonstrable impact on them? If so, what?
          </li>
          <li className="bulletInBullet">
            Do they have any family or close ties in the UK? If so, what is the
            nationality and / or immigration status of their family members?
            What are the ages of any children?{' '}
          </li>
          <li className="bulletInBullet">
            Do they have any criminal convictions?
          </li>
          <li className="bulletInBullet">
            Has anything changed since the last decision in their case / their
            case concluded? If so, do they have any evidence relating to the
            change?{' '}
          </li>
          <li className="bulletInBullet">
            Is there any other reason they are unable to return to their country
            of origin / are unable to leave the UK?
          </li>
          <li className="bulletInBullet">
            Do they have financial support and / or accommodation?{' '}
          </li>
          <li className="bulletInBullet">
            Have they had a{' '}
            <Slugify url={'/legalaid'} classname="legal link">
              legal aids
            </Slugify>{' '}
            lawyer for their immigration case previously?
          </li>
          <br />
          <ol start="8" className="bullet">
            <li className="bullet">
              The issue that you are seeking help with: e.g. advice before
              claiming asylum, representation in an appeal, etc.
            </li>
            <li className="bullet">Your contact details. </li>
          </ol>
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
