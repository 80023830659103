import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3f extends Component {
  state = { url: null }

  handleLink = e => {
    if (e.target.value === 'yes') {
      this.setState({ url: '/sections/3/section3fLeaveVoluntarily' })
    } else if (e.target.value === 'fresh') {
      this.setState({ url: '/sections/4/section4' })
    } else {
      this.setState({ url: '/sections/3/section3fAppeal' })
    }
  }
  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Negative decision at First Tier Tribunal appeal</h1>
            <form className="form" action="#">
              <input
                onClick={this.handleLink}
                type="radio"
                className="stepThreeF"
                value="no"
                name="stepThreeFAppeal"
                id="stepThreeFAppeal"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeFAppeal">
                Appeal
              </label>
              <br />
              <br />
              <input
                onClick={this.handleLink}
                type="radio"
                className="stepThreeF"
                value="fresh"
                name="stepThreeFAppeal"
                id="stepThreeFreshClaim"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeFreshClaim">
                Fresh Claim
              </label>
              <br />
              <br />
              <input
                onClick={this.handleLink}
                type="radio"
                className="stepThreeF"
                value="yes"
                name="stepThreeFAppeal"
                id="stepThreeFLeaveVolontarily"
              />{' '}
              <label
                className="bold agreementCheck"
                for="stepThreeFLeaveVolontarily"
              >
                Leave Voluntarily
              </label>
              <br />
              <br />
            </form>
            <br />
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area">
            <section className="infoBox_step1">
              <h3 className="infoBoxTitle">INFO BOX</h3>
              <h4>What are the next steps after a negative decision?</h4>
              <p>
                <h4>Appeal</h4>
                If a negative decision is received from the First Tier Tribunal
                dismissing an asylum appeal, it may be possible to apply for
                permission to appeal to the Upper Tribunal, but this will only
                be possible if there is an arguable error of law and any
                application must be submitted to the Tribunal within a strict
                deadline. It is, therefore, essential that{' '}
                <Slugify url="/legalaid" classname="link">
                  legal advice
                </Slugify>
                is sought on this possibility as soon as any negative decision
                is received. You can find out more about the court structure{' '}
                <Slugify
                  url="https://themigrantslawproject.org/wp-content/uploads/2017/02/Immigration-legal-system.pdf"
                  classname="link"
                  isExternal
                >
                  here.
                </Slugify>
                and details on appealable and non-appealable decisions{' '}
                <Slugify
                  url="https://themigrantslawproject.org/wp-content/uploads/2017/02/appealable-and-non-appealable-decisions.pdf"
                  classname="link"
                  isExternal
                >
                  here.
                </Slugify>
                There is further helpful guidance if your client is
                unrepresented{' '}
                <Slugify
                  url="https://righttoremain.org.uk/toolkit/ut/"
                  classname="link"
                  isExternal
                >
                  here,
                </Slugify>
                but we emphasise the legal representation is crucial at this
                point.
              </p>
              <p>
                <h4>Fresh Claim/further submissions</h4>
                To make Further Submissions (a Fresh Claim for asylum), but in
                order to do so, they need to{' '}
                <span className="bold">
                  have information or evidence that has not already been
                  submitted.
                </span>{' '}
                It is important to understand that it is not possible to make a
                Fresh Claim on the basis that the Home Office and/or the
                Tribunal simply got it wrong. It is necessary to provide
                information or evidence that was not previously available and is
                significant and material (i.e., it is relevant to the reasons
                the person was not accepted to be a refugee and may cause that
                decision to be reconsidered). This could be new evidence that
                was not available before, relating to the risk they face in
                their country of origin or it could be a change in circumstances
                in their country of origin that means they may now be accepted
                to be at risk. This could take the form of objective evidence
                such as newspaper reports, reports from human rights
                organisations, or documents relating to the client specifically,
                such as arrest warrants from the country of origin, or an expert
                report on the risk faced by the client. Alternatively, a Fresh
                Claim could be based on a change in the UK{' '}
                <span className="italic">caselaw</span> regarding their country
                that means they may now be recognised to be at risk when they
                were not before, usually in the form of Country Guidance cases -{' '}
                <Slugify
                  url="https://www.gov.uk/immigration-asylum-appeal-decisions"
                  isExternal
                  classname="link"
                >
                  these can be searched for on the Tribunal website.
                </Slugify>
                Detailed information on making a Fresh Claim can be found{' '}
                <Slugify
                  classname="link"
                  isExternal
                  url="https://themigrantslawproject.org/wp-content/uploads/2017/08/fresh-claims-reformatted.pdf"
                >
                  here.
                </Slugify>
              </p>
              <p>
                <h4>Leave voluntarily</h4>
                It may be that your client now wishes to leave UK, although this
                is a decision that they need to think carefully about.
              </p>
            </section>
          </section>
        </section>
      </>
    )
  }
}
export default Section3f
