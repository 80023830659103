import React from 'react'
import Slugify from '../../javascript/slugify'
import fireSymbol from '../../Vector.png'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>A positive decision for your client</h1>
        <p>
          If the client has been recognized to be a refugee or he has been
          accepted they otherwise face a serious risk in their country of
          origin, an individual will be granted 5 years leave to remain as a
          refugee or Humanitarian Protection.
        </p>
        <p>
          A grant of leave to remain means an individual has lawful status in
          the UK. If granted leave to remain as a refugee or Humanitarian
          Protection, it also means that you can work, study or claim benefits
          (subject to your financial resources).
        </p>
        <p>
          The client should seek advice and assistance from their local CAB:{' '}
          <Slugify
            classname="link"
            url={'https://www.citizensadvice.org.uk'}
            isExternal
          >
            https://www.citizensadvice.org.uk
          </Slugify>
        </p>
        <p>
          The fact an individual can work and claim benefits means that they are
          no longer deemed to require asylum support or accommodation. This
          means that they will be informed that they have to leave their
          accommodation. It is, therefore, very important that they make an
          application for accommodation as soon a possible. If an individual
          with Refugee status or Humanitarian Protection wishes to study, they
          will be required to pay home (rather than international) student fees.
        </p>
        <p>
          <strong>
            You should not travel on your national passport if you have been
            recognized to be a refugee - you can apply for a refugee travel
            document:{' '}
            <Slugify
              url={
                'https://www.gov.uk/government/publications/apply-for-a-home-office-travel-document-form-td112-brp'
              }
              classname="link"
              isExternal
            >
              https://www.gov.uk/government/publications/apply-for-a-home-office-travel-document-form-td112-brp
            </Slugify>
          </strong>
          <br /> <br />
          If you have been granted Humanitarian Protection, then you may be able
          to apply for a travel document if you are unable to obtain a national
          passport. You should seek advice on this issue.
        </p>
        <p>
          It is very important that an application for settlement / Indefinite
          Leave to Remain is made before your current leave to remain expires -
          This mean that your leave continues under the{' '}
          <strong>Immigration Act</strong> until you receive a decision on your
          application for further leave. If an application is not made before
          your current leave expires, you will become an overstayer and be at
          risk of detention or removal.
        </p>
      </section>
      <section className="aside_Info_Area">
        <section className="aside_flashpoint">
          <div className="gridContainer">
            <img className="grid-fire " src={fireSymbol} alt="" />
            <h3 className="grid-titles">
              BRPs and leaving asylum accommodation
            </h3>
          </div>
          <article>
            <p>
              Following a positive decision, you should receive a Biometric
              Residence Permit (BRP) - this is a card confirming the grant to
              leave. For a BRP to be issued, you will need to provide a passport
              photographs and fingerprints. This may be done in the course of
              the asylum claim or after a positive decision. There can be
              significant delay in issuing BRPs - in this case, speak to your{' '}
              <Slugify url="/legalaid" classname="legal link">
                legal representative
              </Slugify>
              and/or{' '}
              <Slugify url="/mp-checklist" classname="legal link">
                MP
              </Slugify>
              to try to speed the process up. An individual's asylum claim will
              be concluded when they are granted asylum or when their asylum
              claim is otherwise finally determined by their becoming 'appeals
              rights exhausted' (they have no further rights of appeal or no
              grounds to seek a further appeal).
            </p>
            <p>
              Written notice will be provided <strong>21 days</strong> before
              financial support (and, where relevant, accommodation) are
              stopped. It is crucial therefore that clients contact the Job
              Centre to apply for benefits as soon as possible. Most Job Centres
              now have a refugee housing. This is often a difficult point for
              new refugees, and they risk homelessness and destitution - it is
              important that you support them to access the relevant services as
              soon as possible.
            </p>
          </article>
        </section>
        <section className="infoBox_step1">
          <h3 className="infoBoxTitle">INFO BOX</h3>
          <article>
            <h4>Types of leave to remain </h4>
            <p>
              It is important to be aware that there are various types of
              remain. People are not always clear on the type of leave they have
              and what it means for them. Different types of leave to remain
              bring different rights and next steps. <br /> <br />
              <lu>
                <li className="bullet">
                  <strong>Leave to Remain as a Refugee –</strong> this is
                  usually granted for an initial period of 5 years and, prior to
                  the expiry of the 5 years, an application can be made for ILR
                  (see below).
                </li>
                <li className="bullet">
                  <strong>Humanitarian Protection – </strong> this is usually
                  granted for an initial period of 5 years and, prior to the
                  expiry of the 5 years, an application can be made for ILR (see
                  below).{' '}
                </li>
                <li className="bullet">
                  <strong>
                    Leave to remain as an unaccompanied asylum seeking child -{' '}
                  </strong>{' '}
                  A child who is under the age of 17 ½ when their asylum claim
                  is decided, if it is refused, they will be granted a form of
                  limited leave if there are no adequate reception arrangements
                  in the country to which they would be returned. It is
                  important to be aware that, in most cases, the refusal of
                  asylum may be appealed notwithstanding the grant of Leave.
                  Leave granted to a child on this basis is granted for a period
                  of 30 months or until the child turns 17 ½ years old,
                  whichever is shorter.{' '}
                </li>
                <li className="bullet">
                  <strong>Other periods of Leave to Enter or Remain –</strong>{' '}
                  Leave to Enter or Remain in the UK may be granted for varying
                  periods depending on the basis of the decision to grant leave.
                  If Leave is granted on the basis of family and / or private
                  life, it will usually be granted for 30 months with it
                  possible to apply for further leave prior to expiry of the
                  leave until 10 years continuous leave has been accrued, at
                  which time an application for ILR may be made.
                </li>
                <li className="bullet">
                  <strong>Indefinite Leave to Remain – </strong> this is
                  permission to remain in the UK permanently and may have been
                  granted following 5 years Leave to Remain as a Refugee or on
                  other bases. It is important to be aware that if someone is
                  outside of the UK for two years or otherwise establishes
                  themselves in another country this Leave may lapse. If you are
                  applying for ILR after a grant of Leave to Remain as a Refugee
                  or a grant of Humanitarian Protection, the Home Office will
                  perform a Safe Return Review which will consider the
                  circumstances in your country of origin at the time of the
                  application for ILR. If possible, seek legal advice when
                  applying for ILR.
                </li>
              </lu>
              <br />
              Any Leave to Enter or Remain that is not Indefinite is Limited
              (i.e., temporary) and it is essential that any application for
              Further Leave to Remain or for Indefinite Leave to Remain be
              applied for prior to the expiry of the existing Leave otherwise
              the person becomes an over-stayer which is an offence and also
              impacts rights dependant on their Leave, including the right to
              work, to claim benefits or rent accommodation.
            </p>
          </article>
        </section>
      </section>
    </section>
  </>
)
