import React, { Component } from 'react'
import { getSection } from '../../utils'

import Home from './intro'
import Section1Next from './Section1Next'
import Section1EndOfRoute from './Section1EndOfRoute'
import Section1SecondQuestionEndOfRoute from './Section1SecondQuestionEndOfRoute'

export default class Sections extends Component {
  state = {
    stepId: getSection(this.props)
  }

  componentWillMount = () => {}
  componentWillReceiveProps = nextProps => {
    this.setState({ stepId: nextProps.match.params.stepId })
  }

  render() {
    const { stepId } = this.state
    switch (stepId) {
      case 'section1Next':
        return <Section1Next />
      case 'section1EndOfRoute':
        return <Section1EndOfRoute />
      case 'section1SecondQuestionEndOfRoute':
        return <Section1SecondQuestionEndOfRoute />
      default:
        return <Home />
    }
  }
}
