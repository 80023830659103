import React from 'react'
import Slugify from '../../javascript/slugify'
import './legalAid.css'

export default props => {
  return (
    <section className="legalAidPage">
      <aside>
        <i
          onClick={() => props.history.goBack()}
          className="hideLegalAidBtn fa-3x fas fa-times-circle"
        />
      </aside>

      <h1>Legal Aid</h1>
      <section className="legalAidPageScope">
        <article className="article articleScope">
          <h4>Legal Aid is available (in scope) for:</h4>
          <p>
            <span className="keyTerms">
              Asylum - refugee and article 3 cases
            </span>{' '}
            - including applications for further leave if there is still an
            asylum reason to stay in the UK.
          </p>
          <p>
            <span className="keyTerms">Domestic violence cases</span> - where a
            marriage/civil partnership has broken down because of domestic
            violence.
          </p>
          <p>
            <span className="keyTerms">Human trafficking cases</span> - where
            there has been a 'reasonable grounds' decision.
          </p>
          <p>
            <span className="keyTerms">Detention cases</span> - Legal Aid is
            available to people in detention if the lawyer has been working on a
            case for a significant period of if they have a detention centre
            contract (only specific Legal Aid have these contracts).
          </p>

          <p className="bottomMention">
            For legal aid eligibility, see the Means and Merit:
          </p>
          <article className="means">
            <i className="hideMeansBtn fa-3x fas"></i>
            <h4>Means</h4>
            <p>
              Individuals must show that they do not have sufficient money to
              pay a lawyer.
            </p>
            <p>
              <span className="keyTerms">Capital</span> - if they savings or
              possessions worth more than £8,000 they will not normally qualify
              Legal Aid (£3,000 at certain stages).
            </p>
            <p>
              <span className="keyTerms">Income</span> - they must show that
              they have a low income. For example, if they receive asylum
              support ("NASS"), they will qualify for Legal Aid on the means
              criteria.
            </p>
            <p>
              They have to provide evidence of how they supported/ their income
              to a Legal Aid lawyer. For more information on the current limits,
              please{' '}
              <Slugify
                url="https://www.gov.uk/guidance/civil-legal-aid-means-testing#eligibility-limits"
                classname="link"
                isExternal
              >
                see here.
              </Slugify>
            </p>
          </article>
          <article className="merit">
            <i className="hideMeritBtn fa-3x fas"></i>
            <h4>Merit</h4>
            <p>
              An individual's case has to be strong enough to justify Legal Aid.
              For applications to the Home Office the question is: would someone
              who was paying themselves think i was worth spending money on
              advice and representation ?
            </p>
            <p>
              There will usually be merit in obtaining advice and representation
              for an asylum claim pre-decision.
            </p>
            <p>
              For appeals, the case must have at least a 50% chance of success
              (or the chances must be unclear).
            </p>
          </article>
        </article>
        <article className="article">
          <h4>Legal Aid is not available (out of scope) for:</h4>
          <p>
            Other Immigration applications, including:
            <ul className="listContainer">
              <li>Family reunion:</li>
              <li>
                Applications made on Family and Private life grounds (Article 8
                ECHR);
              </li>
              <li>Asylum support only (without accommodation)</li>
              <li>
                Representation before the First-Tier Tribunal - Asylum Support
              </li>
            </ul>
          </p>
          <p>
            It may be possible to apply for Legal Aid on matters out of scope by
            applying for Exceptional Case Funding (ECF)
          </p>
          <p>
            To apply for Exceptional Case Funding (ECF), an application must be
            made to the Legal Aid Agency showing that the sponsor cannot afford
            to pay for a lawyer and that the case has a reasonable chance of
            success and that,
            <span className="bold">
              if Legal Aid funding is not granted, it will be a breach of EU or
              human rights. See the yellow boxes for more details on means and
              merits.
            </span>
          </p>
          <p>
            You can apply directly to the Legal Aid Agency:{' '}
            <a
              target="_blank"
              className="link"
              rel="noopener noreferrer"
              href="https://www.gov.uk/legal-aid-apply-for-exceptional-case-funding"
            >
              https://www.gov.uk/legal-aid-apply-for-exceptional-case-funding
            </a>
          </p>
          <p>
            The Public Law Project has prepared a guide to applying for ECF{' '}
            <a
              target="_blank"
              className="link"
              rel="noopener noreferrer"
              href="https://publiclawproject.org.uk/resources/legal-aid-exceptional-case-funding-ecf-applying-without-the-assistance-of-an-adviser-or-solicitor/"
            >
              available here.
            </a>
          </p>
        </article>
      </section>
      <section className="referralToSolicitor">
        <p>
          <h4>Making a referral to a solicitor</h4>
          Details of local legal aid solicitors can be found{' '}
          <Slugify
            isExternal
            classname="link"
            url="https://find-legal-advice.justice.gov.uk/"
          >
            here.
          </Slugify>
          It is important to find a lawyer who specialises in immigration and
          asylum cases for individuals. Unless the client is able to pay a
          lawyer, it is important to find a lawyer with a Legal Aid contract for
          immigration (see above). Details of local Law Centres, who can provide
          free advice and representation, can be found{' '}
          <Slugify
            isExternal
            classname="link"
            url="https://www.lawcentres.org.uk/about-law-centres/law-centres-on-google-maps/alphabetically"
          >
            here.
          </Slugify>
          <br />
          There is also list of immigration practitioners{' '}
          <Slugify
            isExternal
            classname="link"
            url="https://ilpa.org.uk/members-directory/"
          >
            here.
          </Slugify>
          It is a good idea to make sure that you have good contacts with local
          law firms and Law Centres.
          <p>
            <span className="bold">
              We strongly recommend that individuals are represented throughout
              the asylum process.
            </span>{' '}
            We have provided a pro forma to support you in making a{' '}
            <Slugify url="/referrals/solicitor" classname="legal link">
              successful referral to a sollicitor.
            </Slugify>{' '}
            There are lots of things you need to do to help your client, but one
            of the most important is accessing legal advice and representation
            where possible
          </p>
        </p>
      </section>
    </section>
  )
}
