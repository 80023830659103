import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3b extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3c' })
      : this.setState({ url: '/sections/3/section3bNo' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Step 3.b</h1>
            <form className="form" action="#">
              <h3>
                Has your client received an invitation for the substantive
                interview?
              </h3>

              <br />
              <div className="listContainer">
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeB"
                  value="yes"
                  className="stepThreeBYes"
                  id="stepThreeBYes"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeBYes">
                  Yes
                </label>
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeB"
                  value="no"
                  className="stepThreeBNo"
                  id="stepThreeBNo"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeBNo">
                  No
                </label>
              </div>
            </form>
            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area">
            <section className="infoBox_step1">
              <article>
                <h4>What is the Substantive Interview?</h4>
                <p>
                  The Substantive Interview is one of the most important stages
                  of the asylum process, where clients get to set out the basis
                  of their asylum claim.
                </p>
                <p>
                  It is an interview, which can last for several hours. It is
                  important to be prepared, and for the client to be able to set
                  out their account as clearly as possible.
                </p>
                <p>
                  It is very important that{' '}
                  <Slugify url={'/legalaid'} classname="link">
                    legal advice
                  </Slugify>{' '}
                  be taken in preparing for this interview.
                </p>
                <p>
                  Helpful information on what to expect at a substantive
                  interview, and tips on preparing, can be found{' '}
                  <Slugify
                    url="https://righttoremain.org.uk/toolkit/asylumiv/"
                    classname="link"
                    isExternal
                  >
                    here.
                  </Slugify>
                </p>
              </article>
            </section>
          </section>
        </section>
      </>
    )
  }
}
export default Section3b
