export const goToBeforeGlossary = props => {
  var beforeGlossaryPath = window.localStorage.getItem('beforeGlossaryPath')
  var newLocation = beforeGlossaryPath === null ? '/' : beforeGlossaryPath
  window.localStorage.clear()
  props.history.push(newLocation)
}

export function goToGlossary(props) {
  window.localStorage.setItem(
    'beforeGlossaryPath',
    props.history.location.pathname
  )
  props.history.push('/glossary')
}

export const getSection = ({ match }) =>
  match ? (match.params.stepId ? match.params.stepId : null) : null
