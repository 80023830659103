import React from 'react'
import Slugify from '../../javascript/slugify'
import { getFirstLetters } from './Dictionary'

export default () => {
  const letters = getFirstLetters().sort()
  return (
    <section className="glossary_letters_container">
      {letters.map((letter, index) => {
        return (
          <div key={index} className="glossary_letters">
            <strong>
              <Slugify
                classname="link-inHeader glossary_Initials"
                url={`/glossary/#${letter}`}
              >
                {letter}
              </Slugify>
            </strong>
          </div>
        )
      })}
    </section>
  )
}
