import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3c extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3cYes' })
      : this.setState({ url: '/sections/3/section3cNo' })
  }

  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <form className="form" action="#">
              <h1>Has your client been to the substantive interview?</h1>
              <br />
              (If they are not sure, use prompts such as: have you been to a
              very long interview? Did they ask you questions about your asylum
              claim?)
              <br />
              <br />
              Check the clients’ documents for the substantive interview record
              <br />
              <br />
              <div className="listContainer">
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeC"
                  value="yes"
                  className="stepThreeCYes"
                  id="stepThreeCYes"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeCYes">
                  Yes
                </label>
                <br />
                <input
                  onClick={this.handleLink}
                  type="radio"
                  name="stepThreeC"
                  value="no"
                  className="stepThreeCNo"
                  id="stepThreeCNo"
                />{' '}
                <label className="bold agreementCheck" for="stepThreeCNo">
                  No
                </label>
                <br />
              </div>
            </form>

            <section className="next next-noRatio">
              <Slugify
                classname={
                  this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
                }
                url={this.state.url}
              >
                Next
              </Slugify>
            </section>
          </section>
          <section className="aside_Info_Area" />
        </section>
      </>
    )
  }
}
export default Section3c
