import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3d extends Component {
  constructor(props) {
    super(props)
    this.state = { url: null }
  }

  handleLink = e => {
    e.target.value === 'yes'
      ? this.setState({ url: '/sections/3/section3dEndOfRoute' })
      : this.setState({ url: '/sections/3/section3e' })
  }

  render() {
    return (
      <section className="info_Area">
        <section className="main_Info_Area">
          <h1>Initial Decision</h1>
          <form className="form" action="#">
            <h3>
              Has your client received a decision from the Home Office about
              their claim for asylum?
            </h3>
            <br />
            <div className="listContainer">
              <input
                onClick={this.handleLink}
                type="radio"
                name="stepThreeD"
                value="yes"
                className="stepThreeDYes"
                id="stepThreeDYes"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeDYes">
                Positive Decision
              </label>
              <br />
              <br />
              <input
                onClick={this.handleLink}
                type="radio"
                name="stepThreeD"
                value="no"
                className="stepThreeDNo"
                id="stepThreeDNo"
              />{' '}
              <label className="bold agreementCheck" for="stepThreeDNo">
                Negative Decision
              </label>
              <br />
            </div>
          </form>
          <section className="next next-noRatio">
            <Slugify
              classname={
                this.state.url !== null ? 'NextButton' : 'NextButtonPassive'
              }
              url={this.state.url}
            >
              Next
            </Slugify>
          </section>
        </section>
        <section className="aside_Info_Area">
          <section className="infoBox_step1">
            <article>
              <h4>
                How the Home Office notifies asylum seekers of decisions on
                their claim
              </h4>
              <p>
                Usually, you will be informed of the decision in writing. A copy
                will be sent to you directly at the last address that you gave
                the Home Office, and a copy will be sent to your legal
                representative if you have one. In some cases, people are
                provided with the decision when they report to the Immigration
                Service. If you are given any documents or letters when you
                report to the Immigration Service, you should share the
                documents or letters with your caseworker, solicitor, or anyone
                who is helping you. It is important to seek legal advice about
                the information in the document or letter as soon as possible.
                Usually, if it is a positive decision - that is, a decision to
                grant you asylum, the letter will state the decision on the
                front page. If it is a negative decision, the letter will
                usually be longer, and the decision may be at the end of the
                letter. Negative decisions can include a refusal of asylum but a
                grant of another form of leave, such as Leave to Remain as an
                Unaccompanied Asylum Seeking Child. It is important to read the
                whole letter carefully. If it is a refusal, you should be
                provided with appeal forms. In the event of a refusal, even if
                you have been granted another form of Leave, you may still be
                able to appeal the refusal of asylum. It is crucial to obtain{' '}
                <Slugify url={'/legalaid'} classname="legal link">
                  legal advice
                </Slugify>
                as soon as possible. The letter will be in English and will not
                be translated into any other language.
              </p>
            </article>
          </section>
        </section>
      </section>
    )
  }
}

export default Section3d
