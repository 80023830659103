import React from 'react'
import Slugify from '../../javascript/slugify'

const dictionary = [
  {
    tag: 'General terms relating to immigration status',
    term: 'Entry clearance',
    definition:
      'An application made from abroad normally granted by an Entry Clearance Officer (ECO) in the form of a visa before entry to UK'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Leave to Enter',
    definition:
      'Permission to enter granted on arrival in the UK by an immigration officer. A visa operates as pre-existing leave to enter but an immigration officer can still refuse leave to enter even if a visa has been granted'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Leave to Remain',
    definition:
      'An application made in the UK normally granted by the Home Office / Border Agency. Usually refers to an extension (or variation) of leave to remain but can also be granted to a person to regularise their immigration status in the UK'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Illegal Entrant',
    definition:
      'A legal status to describe a person who has entered the UK illegally either by clandestine means or through the use of deception to obtain a visa. An illegal entrant will be served with papers to confirm the status and their liability to removal from the UK'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Overstayer',
    definition:
      'A person who has remained in the UK after their leave to enter or remain has expired. An overstayer is liable to removal from the UK'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Temporary  Admission',
    definition:
      'Formal recognition of a person’s presence in the UK accompanied by a statement of conditions the person must fulfil. Not a grant of permission to be in the UK. Normally issued by an immigration officer at the border or enforcement office while a decision about leave to enter or remain is awaited. Now, replaced by immigration bail, but was previously used as an alternative to detention/bail'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'Conditions of leave',
    definition:
      'the terms on which someone may remain in the UK – if breached, may invalidate leave. For example, no permission to work or to study'
  },
  {
    tag: 'General terms relating to immigration status',
    term: 'NRPF restriction',
    definition:
      'No Recourse to Public Funds restriction – may be imposed on a grant of Leave to Enter or Remain and means an individual is not able to apply for benefits / housing and other public funds under the terms of their visa. It may be possible to apply for this to be lifted in certain circumstances',
    note:
      'an NRPF restriction does not preclude an application for asylum accommodation and / or support if an individual is a destitute asylum seeker (see asylum support section below)',
    link:
      'http://www.nrpfnetwork.org.uk/information/Pages/not-public-funds.aspx'
  },
  {
    tag: 'Protection issues',
    term: 'Asylum',
    definition:
      'claim for protection based on the Refugee Convention and / or Article 3 of the European Convention Human Rights (ECHR).',
    link: 'https://righttoremain.org.uk/toolkit/asylumintro/'
  },
  {
    tag: 'Asylum process',
    term: 'Asylum Screening Unit (ASU)',
    definition:
      'office of the Home Office located in Croydon where in-country asylum claims are made. Note: an appointment should usually be made in advance of attending unless the individual has nowhere to live / is homeless'
  },
  {
    tag: 'Asylum process',
    term: 'Asylum Registration Card (ARC)',
    definition:
      'a card issued at the ASU (see below) or following attendance at the ASU – confirms an individual has sought asylum and contains biometric information, including an individual’s photograph and fingerprints'
  },
  {
    tag: 'Asylum process',
    term: 'SEF – Statement of Evidence Form',
    definition:
      'form completed with details of an individual’s asylum claim – primarily, used in relation to children now'
  },
  {
    tag: 'Asylum process',
    term: 'Determination',
    definition: 'decision of the Court on an appeal'
  },
  {
    tag: 'Asylum process',
    term: 'Appeal dismissed',
    definition: 'Court refusal of appeal'
  },
  {
    tag: 'Asylum process',
    term: 'Appeal allowed',
    definition: 'Court allowing / accepting appeal'
  },
  {
    tag: 'Asylum process',
    term: 'Judicial review',
    definition:
      'potential avenue of challenge if an individual does not have a right of appeal'
  },
  {
    tag: 'Asylum process',
    term: 'Fresh claim / further submissions',
    definition:
      'often incorrectly used interchangeably – a fresh claim usually relates to a new claim for asylum following a previously unsuccessful asylum claim. The process involves further submissions being made to the Home Office, following which the Home Office will consider whether the further submissions amount to a fresh claim'
  },
  {
    tag: 'Asylum process',
    term: 'Further Submissions’ Unit',
    definition:
      'office of the Home Office located in Liverpool where further submissions can be submitted following an unsuccessful asylum claim. Note: an appointment must be made in advance of attending'
  },
  {
    tag: 'Asylum support',
    term: 'NASS',
    definition:
      'National Asylum Support Service – this is no longer the current term, but is frequently used as short hand for Asylum support and Accommodation',
    link: 'https://www.asaproject.org/resources'
  },
  {
    tag: 'Asylum support',
    term: 'Section 95 support',
    definition:
      'this can include accommodation and / or financial support. It is available to destitute asylum seekers until their asylum claim is finally determined, either by the Home Office or the Courts'
  },
  {
    tag: 'Asylum support',
    term: 'Section 98 support',
    definition:
      'this is usually provided on a full-board basis in Home Office hostels. It is a form of temporary support available to destitute asylum seekers whilst they await a decision on their application for section 95 asylum support'
  },
  {
    tag: 'Asylum support',
    term: 'Section 4 support',
    definition:
      'this can include accommodation and financial support in the form of vouchers. It may be available to a destitute failed / refused asylum seeker if they can show that there is a barrier preventing them from leaving the UK'
  },
  {
    tag: 'Asylum support',
    term: 'BRP',
    definition:
      'Biometric Residence Permit – a document confirming the immigration status of an individual. These contain biometric information (ie photo and fingerprints). A permit will be applied for and given as part of most applications for leave to enter or remain. The document can be used as proof of identity, immigration status and any conditions of stay. This will also include information on whether the individual can access public funds'
  },
  {
    tag: 'Types of Leave',
    term: 'Refugee Status',
    definition:
      'A grant of leave to enter or remain in recognition that removal from the UK would amount to a breach of the 1951 Refugee Convention'
  },
  {
    tag: 'Types of Leave',
    term: 'Humanitarian Protection',
    definition:
      'A grant of leave to remain - Subsidiary protection sometimes granted when a person does not qualify for full refugee status'
  },
  {
    tag: 'Types of Leave',
    term: 'Discretionary Leave',
    definition:
      'A grant of leave to remain. Normally granted in compassionate circumstances or where removal would breach a person’s human rights'
  },
  {
    tag: 'Types of Leave',
    term: 'UASC leave',
    definition:
      'Unaccompanied Asylum Seeking Child leave - a grant of leave to an in recognition of the fact there are no adequate reception arrangements for a child in the country of origin. Accompanies refusal of an asylum claim'
  },
  {
    tag: 'Types of Leave',
    term: 'Limited Leave to Remain',
    definition:
      'a grant of leave for a limited period – may be any granted under various Immigration Rules or outside the Immigration Rules on human rights’ grounds'
  },
  {
    tag: 'Types of Leave',
    term: 'Indefinite Leave to Remain / Settlement / Permanent Residence',
    definition:
      'permission to remain in the UK with no time restrictions (differing terms may indicate that the permission to remain permanently has been granted in differing circumstances). Note: permission to remain in the UK lapses if the individual is outside the UK for two years+ and may otherwise be revoked in certain circumstances'
  },
  {
    tag: 'Types of Leave',
    term: 'ARE – Appeals’ Rights’ Exhausted',
    definition:
      'when an asylum seeker has been refused asylum (or any other type of application for leave to remain) and has no further rights of appeal (either because they have exhausted the appeals process or because they can appeal no further because there has been no error of law)'
  },
  {
    tag: 'Documentation for travel',
    term: 'Travel document',
    definition:
      'refugee or statelessness – can be obtained by an individual recognized to be a refugee or recognized to be stateless in recognition of their status as an individual who cannot seek the protection of the authorities of their country of origin (refugee) or are otherwise unable to obtain a passport (stateless)'
  },
  {
    tag: 'Documentation for travel',
    term: 'Certificate of identity',
    definition:
      'document that can be sought by an individual with leave to remain in the UK (usually Humanitarian Protection or Discretionary Leave) who has not been recognized to be a refugee or to be stateless, but is unable to obtain a passport from the authorities of their country of nationality (this will need to be explained and evidenced as far as possible). Note: Certificate of Identities are not widely accepted and it is, therefore, important to check with any country to which an individual seeks to travel that they will accept a certificate of identity before applying and paying the application fee'
  },
  {
    tag: 'Documentation for travel',
    term: 'UFF - EU uniform format form',
    definition:
      'single page document that may be issued for an individual to travel to the UK on if they do not have a national passport or other travel document'
  },
  {
    tag: 'Documentation for travel',
    term: 'Immigration Health surcharge',
    definition:
      'to be paid when making an immigration application (for leave to enter or to remain in the UK) if the application is not based on asylum. Calculated per individual applicant and per year of the leave to remain applied for'
  },
  {
    tag: 'Detention and release',
    term: 'Detention',
    definition:
      'An administrative power to detain a foreign national who is liable to removal or deportation'
  },
  {
    tag: 'Detention and release',
    term: 'Bail',
    definition:
      'Order to release a person from detention made by the immigration authorities or the Tribunal. May include conditions such as the requirement to report, to live at a specified address or to provide a surety'
  },
  {
    tag: 'Detention and release',
    term: 'Temporary admission',
    definition:
      'now immigration bail, although this term may still be used (see above)'
  },
  {
    tag: 'Detention and release',
    term: 'Habeas Corpus',
    definition:
      'An ancient writ served in the High Court to produce a person before the court. Can be used to challenge the power to detain in conjunction with Judicial Review of detention'
  },
  {
    tag: 'Detention and release',
    term: 'Reporting restrictions',
    definition:
      'requirement that an individual report to immigration at a specified time, date and location'
  },
  {
    tag: 'Removal from the UK',
    term: 'Deportation',
    definition:
      'An order made by the Secretary of State to remove a foreign national whose presence in the UK is considered to be ‘not conducive to the public good. The effect of a deportation order is to cancel existing leave to enter or remain and to bar the person from returning to the UK for a certain period of time.'
  },
  {
    tag: 'Removal from the UK',
    term: 'Administrative Removal',
    definition:
      'A specific legal provision to remove a person who has overstayed their visa'
  },
  {
    tag: 'Removal from the UK',
    term: 'Removal',
    definition:
      'A general power to remove a person who has no right of residence or no leave to enter or remain in the UK'
  }
]

export function getTags() {
  const tags = dictionary.map(dicTerm => dicTerm.tag)

  return Array.from(new Set(tags)).sort()
}

export function getFirstLetters() {
  return Array.from(new Set(dictionary.map(word => word.term.charAt(0))))
}

function compare(a, b) {
  if (a.term < b.term) {
    return -1
  }
  if (a.term > b.term) {
    return 1
  }
  return 0
}

export function getWordsByLetter(letter) {
  console.log({ letter })
  return dictionary
    .filter(word => word.term.charAt(0) === letter)
    .sort(compare)
    .map((word, index) => {
      return (
        <section key={index}>
          <p>
            <span className="keyTerms">{word.term}</span>{' '}
            <span className="label label-primary">{word.tag}</span>
            <br />{' '}
            {word.definition.charAt(0).toUpperCase() + word.definition.slice(1)}
            <br />
            {word.link && (
              <span>
                <i className="fa-1 fas fa-link" />{' '}
                <Slugify url={word.link} classname="link" isExternal>
                  More info
                </Slugify>
              </span>
            )}
          </p>
          <br />
        </section>
      )
    })
}

export function getWordsByTag(tag) {
  if (!tag) return
  return dictionary
    .filter(word => word.tag === tag)
    .sort(compare)
    .map((word, index) => {
      return (
        <section key={index}>
          <p>
            <span className="keyTerms">{word.term}</span>{' '}
            <span className="label label-primary">{word.tag}</span>
            <br /> {word.definition}
            <br />
            {word.link && (
              <span>
                <i className="fa-1 fas fa-link" />{' '}
                <Slugify url={word.link} classname="link" isExternal>
                  More info
                </Slugify>
              </span>
            )}
          </p>
          <br />
        </section>
      )
    })
}
