import React from 'react'
import { goToGlossary } from '../utils'
import Slugify from '../javascript/slugify'

export default props => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Asylum Law Guide</h1>
        <h3>
          To assist frontline workers and volunteers to understand the various
          stages of the complex asylum process.
        </h3>
        <p>
          This tool will help you to understand the stage of the process the
          client might be at and, given that, how best to support the client, as
          well as helping them to plan for the future, and potentially avoid
          crisis points and costly mistakes. It will link to other resources
          which can assist in related matters, such as access to{' '}
          <Slugify url="/legalaid" classname="link">
            legal aid.
          </Slugify>
        </p>
        <p>
          A key area is ensuring that clients have copies of their papers at all
          stages of the process. This is important as it helps them, and those
          supporting them, to understand their journey through the asylum
          process and to assist their progress through the system. It will help
          you to understand where they are in the process and enable you to
          support them appropriately as they access your services. While the
          process we outline might seem long, lack of documents, missed
          appointments, and assumptions about where someone is in the system can
          be catastrophic, leading to destitution, detention, or removal
        </p>
        <p>
          We aim to use straightforward language in all of our work. There is a
          board and complex vocabulary surrounding immigration law, which, can
          be confusing. We provide a{' '}
          <button
            className="link-inHeader link_inline_font"
            onClick={() => {
              return goToGlossary(props)
            }}
          >
            Glossary of terms
          </button>
          to make things easier to understand.
        </p>
        <section className="next-noRatio ">
          <Slugify classname="NextButton" url="/acknowledgement">
            Next
          </Slugify>
        </section>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
