import React from 'react'

import Slugify from './javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Site Map</h1>

        <ul className="noBullets">
          <li>
            <Slugify classname="link rebrandLink" url="/referrals/solicitor">
              Solicitor referral
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url="/acknowledgement">
              Acknowledgement
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url="/sections/1/">
              Section 1 - Do you want to claim asylum?
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url="/sections/1/section1EndOfRoute"
            >
              Section 1 - Other types of Leave
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url="/sections/2/">
              Section 2 - Awaiting content
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url="/sections/3/">
              Section 3 - Ensuring the correct first steps in claiming asylum
              have been completed and clients are aware of their rights and
              responsibilities
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3a'}>
              Section 3 - Where is my client in the asylum process and how can I
              help ?
            </Slugify>
          </li>

          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3first'}
            >
              Section 3 - Does the client have legal representation?
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3aNo'}
            >
              Section 3 - The client has not had a Screening Interview
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3aEndOfRoute'}
            >
              Section 3 - End of route
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3b'}>
              Section 3 - Has your client received an invitation for the
              substantive interview?
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3bNo'}
            >
              Section 3 - Help your client to prepare for the substantive
              interview
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3c'}>
              Section 3 - Has your client been to the substantive interview?
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3cYes'}
            >
              Section 3 - Supporting your client after the substantive interview
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3cNo'}
            >
              Section 3 - Helping a client prepare for their substantive
              interview
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3d'}>
              Section 3 - Initial Decision
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/sectiondEndOfRoute'}
            >
              Section 3 - Ensuring the correct first steps in claiming asylum
              have been completed and clients are aware of their rights and
              responsibilities
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3e'}>
              Section 3 - Appealing a negative initial asylum decision
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/3/section3f'}>
              Section 3 - Negative decision at First Tier Tribunal appeal
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3fAppeal'}
            >
              Section 3 - Appealing a negative decision
            </Slugify>
          </li>
          <li>
            <Slugify
              classname="link rebrandLink"
              url={'/sections/3/section3secondEndOfRoute'}
            >
              Section 3 - Supporting an application for asylum support
            </Slugify>
          </li>

          <br />
          <li>
            <Slugify classname="link rebrandLink" url={'/sections/4/section4'}>
              Section 4 - Fresh Claim/Further submissions
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url={'/legalaid'}>
              Legal Aid
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/mp-checklist'}>
              MP letter
            </Slugify>
          </li>
          <li>
            <Slugify classname="link rebrandLink" url={'/asylum-support'}>
              Asylum Support
            </Slugify>
          </li>
          <br />
          <li>
            <Slugify classname="link rebrandLink" url={'/glossary'}>
              Glossary
            </Slugify>
          </li>
        </ul>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
