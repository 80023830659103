import React, { Fragment } from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <Fragment>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h3>Supporting an application for asylum support</h3>
        <p>
          To make a claim for asylum support (Section 95), it is necessary to
          show that you are destitute: that you do not have adequate income to
          meet living expenses for yourself and any dependents now or within the
          next 14 days, and that you are not able to earn income from
          employment. This may involve explained how you are surviving and how
          you were surviving before. If any evidence is available, for example a
          letter from a friend who had been accommodating and feeding you, but
          is no longer able to do so, then that should be submitted. The home
          Office provides guidance on applying for asylum support, including the
          form you need to fill, here:{' '}
          <Slugify
            classname="link"
            url="https://www.gov.uk/asylum-support/how-to-claim"
            isExternal
          >
            www.gov.uk/asylum-support/how-to-claim
          </Slugify>
        </p>
        <p>
          There are a series of useful factsheets from the Asylum Support
          Appeals Project on proving destitution, types of asylum support, and
          other key issues which you should use,{' '}
          <Slugify
            classname="link"
            url="http://www.asaproject.org/resources"
            isExternal
          >
            available here.
          </Slugify>
          Information on the rights and restrictions of asylum seekers can be
          found{' '}
          <Slugify
            classname="link"
            url="https://themigrantslawproject.org/wp-content/uploads/2018/02/Rights-and-restrictions-reformatted.pdf"
            isExternal
          >
            here.
          </Slugify>{' '}
          <strong>Migrant Help</strong> can advise and assist with applications,
          find their details{' '}
          <Slugify
            classname="link"
            url="https://www.migranthelpuk.org/about-asylum-services"
            isExternal
          >
            here.
          </Slugify>
        </p>
        <p>
          The situation can be more complicated if someone has been refused
          asylum and exhausted their rights of appeal, although if there are
          children involved then it may be appropriate for support to be
          provided by social services and/or if a fresh asylum claim has been
          submitted a different type of asylum support accommodation may be
          sought. Advice should be sought regarding an application if it is
          complicated or has been refused.
        </p>
        <p>
          <Slugify
            classname="link"
            url="https://www.asaproject.org/"
            isExternal
          >
            The Asylum Support Appeals Project
          </Slugify>{' '}
          may be able to advise on applications that have been refused, although
          they do not provide advice direct to clients and will need to be
          contacted by an advisor / organization. Please note that the Asylum
          Support Appeals Project (ASAP) does not give legal advice to
          individuals over email, letter or telephone.
        </p>

        <p>
          <Slugify classname="NextButton" url="/">
            Back to the Start
          </Slugify>
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </Fragment>
)
