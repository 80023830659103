import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Other types of Leave</h1>
        <h3>Other types of Leave</h3>
        <p>
          It might be necessary to explore other types of Leave to Remain.
          Sometimes people do not have a claim for protection, but have good
          reasons they are unable to Leave the UK. For example, because they
          have been in the UK for a long time and/or have family in the UK.
        </p>
        <p>
          In this case, other types of application for Leave to Remain might be
          explored.
        </p>
        <p>
          Leave to Remain can be granted on number of different bases and for
          different periods, depending on the basis on which it was
          sought/granted. Information about different types of leave to remain
          can be found{' '}
          <Slugify
            classname="link"
            url="https://www.gov.uk/browse/visas-immigration/settle-in-the-uk"
            isExternal
          >
            here.
          </Slugify>
        </p>
        <p>
          For many people, it is because they have spent a long time in the UK
          and have a private life and family here. More information on Leave to
          Remain on the basis of family life can be found <br />
          <Slugify
            classname="link"
            url={
              'https://themigrantslawproject.org/wp-content/uploads/2017/02/Right-to-private-and-family-life-reformatted.pdf'
            }
            isExternal
          >
            here.
          </Slugify>
        </p>

        <br />
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
