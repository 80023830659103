import React, { Component } from 'react'
import fireSymbol from '../../Vector.png'
import { recordEvent } from '../../Tracking/index'
import Slugify from '../../javascript/slugify'

export const DetentionFlashPoint = () => (
  <aside className="aside_flashpoint">
    <div className="gridContainer">
      <img className="grid-fire " src={fireSymbol} alt="" />
      <h3 className="grid-titles">DETENTION</h3>
    </div>
    <br />
    In the majority of cases, asylum seekers are liable to detention, although
    in many cases they will not actually be detained, but will be given
    immigration bail (previously referred to as Temporary Admission), either of
    which will usually include a requirement that the person reports to the
    Immigration Service regularly.
    <br />
    <br />
    There are key points when individuals may be at risk of detention, including
    when they first claim asylum; and / or when they report to the Immigration
    Service or otherwise come in to contact with the Immigration Service having
    become Appeal Rights Exhausted. <br />
    <br />
    If detained - if they have a legal representative acting, contact them.
    Otherwise, unless a legal representative is engaged and has performed a
    significant amount of work on the case, only specified firms are able to
    advise and assist people in detention centres. To see someone from one of
    these firms, the person in detention should put their name on the detention
    centre legal advice rota (usually in the detention centre library, but ask
    staff). For more information on how to support someone in detention, please
    contact{' '}
    <Slugify
      classname="link"
      url={'https://www.detentionaction.org.uk/'}
      isExternal
    >
      Detention Action
    </Slugify>
  </aside>
)

class Acknowledgement extends Component {
  state = {
    url: null
  }

  handleLink = e => {
    this.setState({
      url: '/sections/1'
    })
    recordEvent('Click Acknowledgment', 'Acknowledgment')
  }

  render() {
    const { url } = this.state
    return (
      <section className="info_Area">
        <section className="main_Info_Area">
          <section className="acknowledgementBox">
            <section className="asylumMain">
              <h1>Acknowledgment</h1>
              <p>
                Please note that free legal advice and representation is
                potentially available at all stages of the asylum process for
                those who do not have the income to pay for representation
                themselves. Access to{' '}
                <Slugify url={'/legalaid'} classname="legal link">
                  legal aid
                </Slugify>{' '}
                is subject to means and merits assessments. Ideally, clients
                should be represented throughout the process. However this is
                not always the case.
              </p>
              <section className="gridContainer">
                <img className="grid-fire" src={fireSymbol} alt="" />

                <p className="grid">
                  We will highlight key{' '}
                  <i className="flashpoints">flashpoints</i> where you should
                  check whether someone has / continues to have legal
                  representation, and explain how you might support clients to
                  access free legal advice and representation. For more
                  information on{' '}
                  <Slugify url={'/legalaid'} classname="legal link">
                    legal aid.
                  </Slugify>
                </p>
              </section>
            </section>
          </section>
          <section className="agreement">
            <input
              onChange={this.handleLink}
              type="radio"
              className="checkbox"
              id="acknowledgement"
            />
            <label className="agreementCheck" for="acknowledgement">
              I acknowledge that I have read the paragraph above and that I will
              pay attention to <i className="flashpoints">flashpoints</i>
              <span className="checkmark" />
            </label>
          </section>
          <section className="next-noRatio">
            <Slugify
              url={url ? url : '#'}
              classname={url ? 'NextButton' : 'NextButtonPassive'}
            >
              Next
            </Slugify>
          </section>
        </section>
        <section className="asylumAside aside_Info_Area">
          <DetentionFlashPoint />
        </section>
      </section>
    )
  }
}

export default Acknowledgement
