import React, { Component } from 'react'
import Slugify from '../../javascript/slugify'

class Section3fLeave extends Component {
  render() {
    return (
      <>
        <section className="info_Area">
          <section className="main_Info_Area">
            <h1>Leave Voluntarily</h1>
            <p>
              If client wants to leave, ensure that they are clear on this and
              what it means for them. This is often an emotionally difficult
              time, and clients will need support. Clients should seek legal
              advice, as it may be possible to appeal the decision. If{' '}
              <Slugify url={'/legalaid'} classname="legal link">
                legal advice
              </Slugify>{' '}
              has been obtained and they wish to return to their country of
              origin, they should not just leave the country. They may be able
              to obtain assistance from the UK authorities. This may impact
              future applications to come back to the UK. More information is
              available on the Home Office website:{' '}
            </p>
            <p>
              <Slugify
                url={'https://www.gov.uk/return-home-voluntarily/'}
                isExternal
                classname="link"
              >
                https://www.gov.uk/return-home-voluntarily/
              </Slugify>
            </p>
            <p>
              <Slugify
                url={
                  'https://www.gov.uk/government/publications/assisted-return-application-form/'
                }
                isExternal
                classname="link"
              >
                https://www.gov.uk/government/publications/assisted-return-application-form/
              </Slugify>
            </p>
            <p>
              <Slugify
                url={
                  'https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/558202/Facilitated-returns-scheme-v8_0.pdf'
                }
                isExternal
                classname="link"
              >
                https://assets.publishing.service.gov.uk/government/uploads/system/uploads/
                attachment_data/file/558202/Facilitated-returns-scheme-v8_0.pdf
              </Slugify>
            </p>
            <section className="next-noRatio">
              <p>
                If the client decides to explore other options, please go back
                to:
              </p>
              <div>
                <Slugify classname="NextButton" url="/sections/3/section3f">
                  Appeal
                </Slugify>{' '}
                <Slugify classname="NextButton" url="/">
                  Exit
                </Slugify>
              </div>
            </section>
          </section>
          <section className="aside_Info_Area" />
        </section>
      </>
    )
  }
}
export default Section3fLeave
