import React from 'react'
import LetterList from './LetterList'
import GlossarySideBar from './SideBar'
import { getTags, getWordsByLetter, getWordsByTag } from './Dictionary'
import './Glossary.css'

export default props => {
  const { hash } = props.location
  const selectedSearch = hash !== '' ? decodeURI(hash.replace('#', '')) : 'A'
  const tags = getTags()
  const words = getWordsByLetter(selectedSearch)
  const wordsTags = getWordsByTag(selectedSearch)

  return (
    <section>
      <section className="info_Area">
        <section className="main_Info_Area">
          <LetterList />
          <section>
            <br />
            <h1 className="glossary_Initials">{selectedSearch}</h1>
          </section>
          {words}
          {wordsTags}
        </section>

        <GlossarySideBar {...props} tags={tags} />
      </section>
    </section>
  )
}
