import React from 'react'
import Slugify from '../../javascript/slugify'

const InfoBox = () => (
  <>
    <section className="aside_Info_Area">
      <section className="infoBox_step1">
        <h3 className="infoBoxTitle">INFO BOX</h3>
        <article>
          <p>Asylum support</p>
        </article>
      </section>
    </section>
  </>
)

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>First Steps</h1>
        <p>Awaiting content for document checklist decision tree</p>
        <section className="next-noRatio">
          <Slugify classname="NextButton" url={'/sections/3/section3a'}>
            Next
          </Slugify>
        </section>
      </section>
      <InfoBox />
    </section>
  </>
)
