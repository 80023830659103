import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 1.b</h1>
        <h3>Claiming Asylum</h3>

        <p>
          The Home Office website provides information about how to claim
          asylum:
        </p>
        <p>
          <Slugify
            classname="link"
            url={'https://www.gov.uk/claim-asylum'}
            isExternal
          >
            https://www.gov.uk/claim-asylum
          </Slugify>
        </p>
        <p>
          <strong>
            You should seek{' '}
            <Slugify url={'/legalaid'} classname="link">
              legal advice
            </Slugify>{' '}
            before claiming asylum.
          </strong>{' '}
          To claim asylum once you are in the UK, you will need to contact the
          Asylum Screening Unit to make an appointment before attending a
          screening interview.
          <br />
          The address is Lunar House, 40 Wellesley Road, Croydon CR9 2BY <br />{' '}
          <strong>
            Monday to Thursday: 9am to 4:45pm <br /> Friday: 9am to 4:30pm{' '}
            <br />
            <br />
            Asylum screening unit appointments line:
            <br /> Telephone: 020 8196 4524
          </strong>
        </p>
      </section>
      <section className="aside_Info_Area">
        <section className="infoBox_step1">
          <h3 className="infoBoxTitle">INFO BOX</h3>
          <article>
            <br />
            <h4>
              <b>&#9314;</b> Claiming asylum
            </h4>
            <p>
              An application should be made as soon as possible and any delay
              may have a detrimental impact on the case. An asylum application
              can be made at port on arrival. Otherwise, it is necessary to
              attend the Asylum Screening Unit (see left).
            </p>

            <br />
          </article>
        </section>
      </section>
    </section>
  </>
)
