import React from 'react'
import Slugify from '../../javascript/slugify'

import fireSymbol from '../../Vector.png'

const AppealsInfoBox = () => (
  <aside className="aside_flashpoint">
    <div className="gridContainer">
      <img className="grid-fire " src={fireSymbol} alt="" />
      <h3 className="grid-titles">ASYLUM SUPPORT AND THE APPEALS SYSTEM</h3>
    </div>
    <p>
      As long as clients are in the process of making an appeal, or waiting for
      an appeal hearing,{' '}
      <Slugify url="/sections/3/section3second" classname="legal link">
        asylum support should not be terminated.
      </Slugify>{' '}
      If asylum support is mistakenly terminated, you should contact{' '}
      <Slugify
        url="https://www.migranthelpuk.org/about-asylum-services"
        classname="legal link"
        isExternal
      >
        Migrant Help
      </Slugify>
      , to explain that the client still has a live asylum claim. The{' '}
      <Slugify
        url="http://www.asaproject.org/"
        classname="legal link"
        isExternal
      >
        Asylum Support Appeals Project
      </Slugify>{' '}
      has further helpful information.{' '}
      <Slugify
        url="https://www.gov.uk/immigration-reporting-centres"
        classname="legal link"
        isExternal
      >
        Clients should continue to report as per their bail conditions or they
        risk being treated as an absconder.
      </Slugify>
    </p>
  </aside>
)

export default () => (
  <section className="info_Area">
    <section className="main_Info_Area">
      <h1>Appealing a negative decision</h1>
      <div className="infoBox_Index"> </div>

      <p>
        If a negative decision is received from the First Tier Tribunal
        dismissing an asylum appeal, it may be possible to apply for permission
        to appeal to the Upper Tribunal, but this will only be possible if there
        is an arguable error of law and any application must be submitted to the
        Tribunal within a strict deadline. It is, therefore, essential that
        legal advice is sought on this possibility as soon as any negative
        decision is received.
      </p>
      <p>
        <span className="bold">
          At this stage, or if a case is within the Upper Tribunal or other
          Higher Courts or if it involves judicial review, the issues are likely
          to be legally complicated and it is extremely important the client has{' '}
          <Slugify url={'/legalaid'} classname="legal link">
            legal advice and representation
          </Slugify>
        </span>
      </p>
      <p>
        Throughout the appeals process, it is very important that people
        continue to report as required, keep in contact with their lawyer and
        keep copies of all papers in their case. They should still be receiving
        asylum support - find out more{' '}
        <Slugify
          url="/sections/3/section3secondEndOfRoute"
          classname="legal link"
        >
          here.
        </Slugify>
      </p>
      <p>
        <h4>How many appeals a client can have and how can you help?</h4>
        <br />
        The issue of appeals beyond the First Tier Tribunal is complex and it is
        strongly advised that is obtained. If seeking a second opinion, or
        further{' '}
        <Slugify url={'/legalaid'} classname="legal link">
          legal advice
        </Slugify>
        , it is essential that copies of all decisions and letters from previous
        legal representatives are provided to any new lawyer. If a client comes
        to you at this stage it is crucial that you make a{' '}
        <Slugify url={'/referrals/solicitor'} classname="legal link">
          referral to a solicitor
        </Slugify>{' '}
        as soon as possible. With any appeal there are stringent deadlines by
        which any appeal must be made and it is, therefore, essential{' '}
        <Slugify url={'/legalaid'} classname="legal link">
          legal advice
        </Slugify>{' '}
        is sought urgently after receipt of any decisions.
      </p>

      <section className="next-noRatio">
        <Slugify classname="NextButton" url={'/sections/4/section4'}>
          Clients’ appeals are ultimately unsuccessful
        </Slugify>
        <br />
        <br />
        <br />
        <Slugify classname="NextButton" url={'/sections/3/section3dEndOfRoute'}>
          Client’s appeals are ultimately successful
        </Slugify>
      </section>
    </section>
    <section className="asylumAside aside_Info_Area">
      <AppealsInfoBox />
    </section>
  </section>
)
