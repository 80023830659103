import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 3</h1>

        <h3>End of route</h3>
        <p>
          If someone does not have a copy of their Screening Interview Record,
          check whether they have had an interview and lost the record. A copy
          of the Screening Interview record may be requested in writing from the
          Home Office. If they have in fact not had an interview, check whether
          they have been ask to return to the ASU for an interview. If they have
          not, try try to find out how long it is since they claimed and if they
          have any documents to confirm they have claimed asylum.
        </p>
        <p>
          <span className="bold">
            If they have not had a Screening Interview and have ot been told
            when they need to attend one, there is a serious risk they may not
            yet have formally claimed asylum. It is very important that people
            claim asylum as soon as possible and legal advice must be sought if
            this does not appear to have happened. The client can contact the
            Asylum Screening Unit direct to arrange an appointment to register
            their asylum claim.{' '}
            <Slugify classname="link" url={'/sections/1'}>
              More here
            </Slugify>
          </span>
        </p>
        <p>
          Check they have been complying with reporting restrictions and have
          not moved address without informing the immigration service.{' '}
          <span className="bold">
            If they have moved address without informing Immigration Service or
            have failed to comply with reporting restrictions, they should
            obtain legal advice immediately, as they are likely to be treated as
            an absconder and may be at risk of detention and removal.
          </span>{' '}
          Sometimes, people think that if they are moved from one address in
          asylum accommodation to another, the Home Office will be informed by
          the Asylum Support department, but this is not the case and it is
          still important to inform the Home Office.
        </p>
        <p>
          Once all these things have been checked, and you have established that
          they are just waiting for the Screening Interview, support he client
          by ensuring that they hav legal representation and that they inform
          their lawyer of any concerns they have about delays in their case
          and/or raise any issues with their MP with information about any
          detrimental impact caused by the details, as appropriate (Link to
          delays section). It is very important that your client keep in contact
          with their lawyer, informing them of any change of address, and that
          they report to the immigration services, as required. If someone has
          any concerns about reporting or about the nature of their reporting
          restrictions, these should be discussed with their lawyer.(link to
          reporting information)
        </p>
      </section>
      <section className="aside_Info_Area"></section>
    </section>
  </>
)
