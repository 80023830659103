import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const slugify = ({ url, classname, isExternal, children }) => {
  return isExternal ? (
    <a
      href={url}
      className={classname}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link to={url} className={classname}>
      {children}
    </Link>
  )
}
slugify.defaultProps = {
  isExternal: false
}

slugify.propTypes = {
  url: PropTypes.string.isRequired,
  classname: PropTypes.string,
  isExternal: PropTypes.bool
}

export default slugify
