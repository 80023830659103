import React from 'react'
import Slugify from '../../javascript/slugify'

export default () => (
  <>
    <section className="info_Area">
      <section className="main_Info_Area">
        <h1>Step 3</h1>
        <h3>Next Steps</h3>
        <p>
          If someone does not have a copy of their Screening Interview record,
          check whether they have had an interview and lost the record. A copy
          of the Screening Interview record may be requested in writing from the
          Home Office. If they have in fact not had an interview, check whether
          they have been asked to return to the Asylum Screening Unit (ASU) for
          an interview.{' '}
        </p>
        <p>
          If they have not, try to find out how long it is since they claimed
          asylum and if they have any documents to confirm they have claimed
          asylum, such as an{' '}
          <strong>Asylum Registration Card (ARC Card)</strong>, check they have
          been complying with reporting restrictions and have not moved address
          without informing the Immigration Service.
        </p>
        <p>
          More information about reporting restrictions can be found{' '}
          <Slugify
            classname="link"
            url="https://www.gov.uk/immigration-reporting-centres"
            isExternal
          >
            here.
          </Slugify>
        </p>
        <p>
          Sometimes, people think that if they are moved from one address in
          asylum accommodation to another, the Home Office will be informed by
          the Asylum Support department, but this is not the case and it is
          still important to inform the Home Office.
          <strong>
            {' '}
            If they have moved address without informing the Immigration Service
            or have failed to comply with reporting restrictions, they should
            obtain legal advice immediately, as they are likely to be treated as
            an absconder and may be at risk of detention and removal.
          </strong>
        </p>
        <p>
          Once you have resolved, whether the client has or has not had a
          screening interview, start this app again.
        </p>
      </section>
      <section className="aside_Info_Area" />
    </section>
  </>
)
