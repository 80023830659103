import React from 'react'
import { goToBeforeGlossary } from '../../utils'
import Slugify from '../../javascript/slugify'

import { getTags } from './Dictionary'

export default props => {
  const tags = getTags().sort()
  return (
    <section className="aside_Info_Area_glossary">
      <aside>
        <i
          onClick={() => goToBeforeGlossary(props)}
          className="hideGlossaryBtn fa-3x fas fa-times-circle"
        />
        <span>
          <h3>
            <i className="fa-1 fas fa-tags" /> Categories
          </h3>
        </span>
        <div className="cat_block">
          {tags.map((tag, index) => (
            <Slugify key={index} classname="cat_link" url={`/glossary/#${tag}`}>
              <div className="cat_style">{tag}</div>
            </Slugify>
          ))}
        </div>
      </aside>
    </section>
  )
}
