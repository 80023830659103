import React, { Component } from 'react'
import { getSection } from '../../utils'

import Home from './intro'
import Section3a from './Section3a'
import Section3first from './Section3first'
import Section3second from './Section3second'
import Section3firstEndOfRoute from './Section3firstEndOfRoute'
import Section3secondEndOfRoute from './Section3secondEndOfRoute'
import Section3aEndOfRoute from './Section3aEndOfRoute'
import Section3aNo from './Section3aNo'
import MPchecklist from '../../components/MPchecklist'
import ReferralToSolicitorCheckList from './ReferralToSolicitorCheckList'
import Section3b from './Section3b'
import Section3cYes from './Section3cYes'
import Section3cNo from './Section3cNo'
import Section3bNo from './Section3bNo'
import Section3c from './Section3c'
import Section3d from './Section3d'
import Section3dEndOfRoute from './Section3dEndOfRoute'
import Section3e from './Section3e'
import Section3eEnd from './Section3eEnd'
import Section3f from './Section3f'
import Section3fAppeal from './Section3fAppeal'
import Section3fLeaveVoluntarily from './Section3fLeaveVoluntarily'
import Section3End from './Section3End'

export default class Sections extends Component {
  state = {
    stepId: getSection(this.props)
  }

  componentWillReceiveProps = nextProps => {
    this.setState({ stepId: nextProps.match.params.stepId })
  }

  render() {
    const { stepId } = this.state
    switch (stepId) {
      case 'section3first':
        return <Section3first />
      case 'section3firstEndOfRoute':
        return <Section3firstEndOfRoute />
      case 'section3secondEndOfRoute':
        return <Section3secondEndOfRoute />
      case 'section3second':
        return <Section3second />
      case 'section3a':
        return <Section3a />
      case 'section3aNo':
        return <Section3aNo />
      case 'MPchecklist':
        return <MPchecklist />
      case 'ReferralToSolicitorCheckList':
        return <ReferralToSolicitorCheckList />
      case 'section3aEndOfRoute':
        return <Section3aEndOfRoute />
      case 'section3b':
        return <Section3b />
      case 'section3bNo':
        return <Section3bNo />
      case 'section3c':
        return <Section3c />
      case 'section3cNo':
        return <Section3cNo />
      case 'section3cYes':
        return <Section3cYes />
      case 'section3d':
        return <Section3d />
      case 'section3dEndOfRoute':
        return <Section3dEndOfRoute />
      case 'section3e':
        return <Section3e />
      case 'section3eEnd':
        return <Section3eEnd />
      case 'section3End':
        return <Section3End />
      case 'section3f':
        return <Section3f />
      case 'section3fAppeal':
        return <Section3fAppeal />
      case 'section3fLeaveVoluntarily':
        return <Section3fLeaveVoluntarily />
      default:
        return <Home />
    }
  }
}
